import LocalStorageService, { CART } from '~/services/LocalStorageService';
import { OMOCOM, BALOISE } from '~/config/insurance';
import {
    ADDITIONAL_DRIVER,
    OMOCOM_BASIC,
    PLATFORM_INSURANCE,
    PRICING_STRATEGY_PER_BOOKING,
    PRICING_STRATEGY_PER_BOOKING_NIGHT,
    OMOCOM_PACKAGES,
} from '~/config/productTypes';
import { BINDING, INSTANT } from '~/config/bookingMethods';

/* eslint-disable no-param-reassign, no-shadow */
export const mutationTypes = {
    SET_BOOKING_REQUEST: 'CART/SET_BOOKING_REQUEST',
    SET_MESSAGE: 'CART/SET_MESSAGE',
    UPDATE_CART_TIMESTAMP: 'CART/UPDATE_CART_TIMESTAMP',
    CART_RESET: 'CART/RESET',
    SET_CART_VEHICLE: 'CART/SET_CART_VEHICLE',
    SET_CURRENT_CART_STEP: 'CART/SET_CURRENT_CART_STEP',
    SET_CART_PRICES_LOADING: 'CART/SET_CART_PRICES_LOADING',
    SET_CART_VEHICLE_ADDONS: 'CART/SET_CART_VEHICLE_ADDONS',
    SET_CART_VEHICLE_SELECTED_ADDONS: 'CART/SET_CART_VEHICLE_SELECTED_ADDONS',
    ADD_CART_VEHICLE_SELECTED_ADDON: 'CART/ADD_CART_VEHICLE_SELECTED_ADDON',
    UPDATE_CART_VEHICLE_SELECTED_ADDON: 'CART/UPDATE_CART_VEHICLE_SELECTED_ADDON',
    REMOVE_CART_VEHICLE_SELECTED_ADDON: 'CART/REMOVE_CART_VEHICLE_SELECTED_ADDON',
    SHOW_FLOATING_INFO: 'CART/SHOW_FLOATING_INFO',
    SET_CART_LOADING: 'CART/SET_CART_LOADING',
    SET_SHOULD_REFETCH_CART_VEHICLE: 'CART/SET_SHOULD_REFETCH_CART_VEHICLE',
};

export const actionTypes = {
    STORE_BOOKING_REQUEST: 'CART/STORE_BOOKING_REQUEST',
    STORE_PENDING_MESSAGE: 'CART/STORE_PENDING_MESSAGE',
    UPDATE_CART_TIMESTAMP: 'CART/UPDATE_CART_TIMESTAMP',
    LOAD_FROM_STORAGE: 'CART/LOAD_FROM_STORAGE',
    CLEAR: 'CART/CLEAR',
    SET_CART_VEHICLE: 'CART/SET_CART_VEHICLE',
    SET_CURRENT_CART_STEP: 'CART/SET_CURRENT_CART_STEP',
    SET_CART_PRICES_LOADING: 'CART/SET_CART_PRICES_LOADING',
    SET_CART_VEHICLE_ADDONS: 'CART/SET_CART_VEHICLE_ADDONS',
    SET_CART_VEHICLE_SELECTED_ADDONS: 'CART/SET_CART_VEHICLE_SELECTED_ADDONS',
    ADD_CART_VEHICLE_SELECTED_ADDON: 'CART/ADD_CART_VEHICLE_SELECTED_ADDON',
    UPDATE_CART_VEHICLE_SELECTED_ADDON: 'CART/UPDATE_CART_VEHICLE_SELECTED_ADDON',
    REMOVE_CART_VEHICLE_SELECTED_ADDON: 'CART/REMOVE_CART_VEHICLE_SELECTED_ADDON',
    SHOW_FLOATING_INFO: 'CART/SHOW_FLOATING_INFO',
    SET_CART_LOADING: 'CART/SET_CART_LOADING',
    SET_SHOULD_REFETCH_CART_VEHICLE: 'CART/SET_SHOULD_REFETCH_CART_VEHICLE',
};

export const createEmptyState = () => ({
    pendingBookingRequest: null,
    pendingMessage: null,
    cartTimestamp: null,
    cartVehicle: {},
    currentCartStep: null,
    cartPricesLoading: false,
    cartVehicleAddons: [],
    cartVehicleSelectedAddons: [],
    showCartFloatingInfo: false,
    isCartLoading: false,
    shouldRefetchCartVehicle: true, // used to refresh the cart vehicle on page reload and when the user changes the dates
});

function getInitialState() {
    const localStorage = new LocalStorageService();
    let state = localStorage.getItem(CART);

    if (state) {
        state = JSON.parse(state);
    } else {
        state = {};
    }

    return { ...createEmptyState(), ...state };
}

export const state = () => getInitialState();

export const getters = {
    pendingBookingRequest: state => state.pendingBookingRequest,
    hasPendingBookingRequest: state => !!state.pendingBookingRequest,
    pendingMessage: state => state.pendingMessage,
    hasPendingMessage: state => !!state.pendingMessage,
    cartTimestamp: state => state.cartTimestamp,
    cartVehicle: state => state.cartVehicle,
    selectedCartDates: state => {
        const { pendingBookingRequest } = state;

        if (!pendingBookingRequest?.fromDate || !pendingBookingRequest?.toDate) {
            return {
                from: null,
                to: null,
            };
        }

        return {
            from: pendingBookingRequest.fromDate,
            to: pendingBookingRequest.toDate,
        };
    },
    currentCartStep: state => state.currentCartStep,
    cartPricesLoading: state => state.cartPricesLoading,
    isCartLoading: state => state.isCartLoading,
    cartVehicleAddons: state => state.cartVehicleAddons,
    cartVehicleSelectedAddons: state => state.cartVehicleSelectedAddons,
    showCartFloatingInfo: state => state.showCartFloatingInfo,
    shouldRefetchCartVehicle: state => state.shouldRefetchCartVehicle,
    isVehicleWithOmocomInsurance: state => {
        if (!state.cartVehicle?.insuranceVendor) {
            return false;
        }

        return state.cartVehicle.insuranceVendor.name?.toLowerCase() === OMOCOM;
    },
    isVehicleWithBaloiseInsurance: state => {
        if (!state.cartVehicle?.insuranceVendor) {
            return false;
        }

        return state.cartVehicle.insuranceVendor.name?.toLowerCase() === BALOISE;
    },
    cartTotalPrice: (state, getters) => {
        if (!state.cartVehicle.pricing?.totalPrice) {
            return 0;
        }

        const addonsTotal = state.cartVehicleAddons.reduce((total, product) => {
            const productKeyOrType = product.type === ADDITIONAL_DRIVER ? product.type : product.key;

            return total + getters.getCartVehicleAddonPrice(product, productKeyOrType);
        }, 0);

        return state.cartVehicle.pricing.totalPrice + addonsTotal;
    },
    getCartVehicleAddonPrice: (state, getters) => (product, type) => {
        const addon = state.cartVehicleSelectedAddons.find(addon => addon.key === type);

        if (product && addon?.selected) {
            switch (product.pricingStrategy) {
                case PRICING_STRATEGY_PER_BOOKING_NIGHT:
                    if (OMOCOM_PACKAGES.includes(product.key)) {
                        const baseProduct = state.cartVehicleAddons.find(addon => addon.key === OMOCOM_BASIC);

                        return (product.price - baseProduct.price) * state.cartVehicle?.pricing.numberOfNights;
                    }

                    return product.price * state.cartVehicle?.pricing.numberOfNights * addon.quantity;
                case PRICING_STRATEGY_PER_BOOKING:
                    return product.price * addon.quantity;
                default:
                    return product.price * addon.quantity;
            }
        } else {
            return 0;
        }
    },
    isCartVehicleBinding: state => {
        return state.cartVehicle?.bookingMethod === BINDING;
    },
    isCartVehicleInstant: state => {
        return state.cartVehicle?.bookingMethod === INSTANT;
    },
    cartOmocomCoverageProducts: (state, getters) => {
        if (!getters.isVehicleWithOmocomInsurance) {
            return [];
        }

        return getters.cartVehicleAddons?.filter(a => a.type === PLATFORM_INSURANCE);
    },
    currentCartOmocomInsuranceProduct: (state, getters) => {
        if (!getters.isVehicleWithOmocomInsurance) {
            return null;
        }

        const omocomProductKeys = getters.cartOmocomCoverageProducts.map(product => product.key);
        const selectedInsurance = getters.cartVehicleSelectedAddons.find(addon =>
            omocomProductKeys.includes(addon.key)
        );

        const targetKey = selectedInsurance?.key || OMOCOM_BASIC;

        return getters.cartVehicleAddons.find(addon => addon.key === targetKey);
    },
};

export const mutations = {
    [mutationTypes.SET_BOOKING_REQUEST](state, payload) {
        state.pendingBookingRequest = {
            ...(state.pendingBookingRequest || {}),
            ...payload,
        };
    },
    [mutationTypes.SET_MESSAGE](state, payload) {
        state.pendingMessage = { ...payload };
    },
    [mutationTypes.UPDATE_CART_TIMESTAMP](state, timestamp) {
        if (!timestamp) {
            const date = new Date();
            // Format to: yyyy-MM-dd HH:mm:ss
            state.cartTimestamp = `${date.toLocaleDateString('sv-SE')} ${date.toLocaleTimeString('sv-SE')}`;
        } else {
            state.cartTimestamp = timestamp;
        }
    },
    // eslint-disable-next-line no-unused-vars
    [mutationTypes.CART_RESET](state) {
        const emptyState = createEmptyState();

        Object.keys(emptyState).forEach(key => {
            state[key] = emptyState[key];
        });
    },
    [mutationTypes.SET_CART_VEHICLE](state, vehicle) {
        state.cartVehicle = vehicle;
    },
    [mutationTypes.SET_CURRENT_CART_STEP](state, step) {
        state.currentCartStep = step;
    },
    [mutationTypes.SET_CART_PRICES_LOADING](state, loading) {
        state.cartPricesLoading = loading;
    },
    [mutationTypes.SET_CART_VEHICLE_ADDONS](state, addons) {
        state.cartVehicleAddons = addons;
    },
    [mutationTypes.SET_CART_VEHICLE_SELECTED_ADDONS](state, addons) {
        state.cartVehicleSelectedAddons = addons;
    },
    [mutationTypes.ADD_CART_VEHICLE_SELECTED_ADDON](state, { key, quantity }) {
        state.cartVehicleSelectedAddons.push({ key, selected: true, quantity });
    },
    [mutationTypes.UPDATE_CART_VEHICLE_SELECTED_ADDON](state, addon) {
        const existingAddon = state.cartVehicleSelectedAddons.find(existingAddon => existingAddon.key === addon.key);

        if (existingAddon) {
            Object.assign(existingAddon, addon);
        }
    },
    [mutationTypes.REMOVE_CART_VEHICLE_SELECTED_ADDON](state, key) {
        state.cartVehicleSelectedAddons = state.cartVehicleSelectedAddons.filter(addon => addon.key !== key);
    },
    [mutationTypes.SHOW_FLOATING_INFO](state, payload) {
        state.showCartFloatingInfo = payload;
    },
    [mutationTypes.SET_CART_LOADING](state, loading) {
        state.isCartLoading = loading;
    },
    [mutationTypes.SET_SHOULD_REFETCH_CART_VEHICLE](state, payload) {
        state.shouldRefetchCartVehicle = payload;
    },
};

export const actions = {
    [actionTypes.STORE_BOOKING_REQUEST]({ commit }, bookingRequestData) {
        commit(mutationTypes.SET_BOOKING_REQUEST, bookingRequestData);
        commit(mutationTypes.UPDATE_CART_TIMESTAMP);
    },
    [actionTypes.STORE_PENDING_MESSAGE]({ commit }, messageData) {
        commit(mutationTypes.SET_MESSAGE, messageData);
        commit(mutationTypes.UPDATE_CART_TIMESTAMP);
    },
    [actionTypes.LOAD_FROM_STORAGE]({ commit }) {
        const storedState = getInitialState();

        if (storedState.pendingBookingRequest) {
            commit(mutationTypes.SET_BOOKING_REQUEST, storedState.pendingBookingRequest);
        }

        if (storedState.pendingMessage) {
            commit(mutationTypes.SET_MESSAGE, storedState.pendingMessage);
        }

        if (storedState.cartTimestamp) {
            commit(mutationTypes.UPDATE_CART_TIMESTAMP, storedState.cartTimestamp);
        }

        if (storedState.cartVehicle) {
            commit(mutationTypes.SET_CART_VEHICLE, storedState.cartVehicle);
        }

        if (storedState.cartVehicleAddons) {
            commit(mutationTypes.SET_CART_VEHICLE_ADDONS, storedState.cartVehicleAddons);
        }

        if (storedState.cartVehicleSelectedAddons) {
            const mappedAddonsKeys = storedState.cartVehicleAddons.map(addon => addon.key || addon.type);
            const selectedAddons = storedState.cartVehicleSelectedAddons.filter(item =>
                mappedAddonsKeys.includes(item.key)
            );

            commit(mutationTypes.SET_CART_VEHICLE_SELECTED_ADDONS, selectedAddons);
        }
    },
    [actionTypes.CLEAR]({ commit }) {
        commit(mutationTypes.CART_RESET);
    },
    [actionTypes.SET_CART_VEHICLE]({ commit }, vehicle) {
        commit(mutationTypes.SET_CART_VEHICLE, vehicle);
    },
    [actionTypes.SET_CURRENT_CART_STEP]({ commit }, step) {
        commit(mutationTypes.SET_CURRENT_CART_STEP, step);
    },
    [actionTypes.SET_CART_PRICES_LOADING]({ commit }, loading) {
        commit(mutationTypes.SET_CART_PRICES_LOADING, loading);
    },
    [actionTypes.SET_CART_VEHICLE_ADDONS]({ commit }, addons) {
        commit(mutationTypes.SET_CART_VEHICLE_ADDONS, addons);
    },
    [actionTypes.SET_CART_VEHICLE_SELECTED_ADDONS]({ commit }, payload) {
        commit(mutationTypes.SET_CART_VEHICLE_SELECTED_ADDONS, payload);
    },
    [actionTypes.ADD_CART_VEHICLE_SELECTED_ADDON]({ commit }, addon) {
        commit(mutationTypes.ADD_CART_VEHICLE_SELECTED_ADDON, addon);
    },
    [actionTypes.UPDATE_CART_VEHICLE_SELECTED_ADDON]({ commit }, addon) {
        commit(mutationTypes.UPDATE_CART_VEHICLE_SELECTED_ADDON, addon);
    },
    [actionTypes.REMOVE_CART_VEHICLE_SELECTED_ADDON]({ commit }, addon) {
        commit(mutationTypes.REMOVE_CART_VEHICLE_SELECTED_ADDON, addon);
    },
    [actionTypes.SHOW_FLOATING_INFO]({ commit }, payload) {
        commit(mutationTypes.SHOW_FLOATING_INFO, payload);
    },
    [actionTypes.SET_CART_LOADING]({ commit }, loading) {
        commit(mutationTypes.SET_CART_LOADING, loading);
    },
    [actionTypes.SET_SHOULD_REFETCH_CART_VEHICLE]({ commit }, payload) {
        commit(mutationTypes.SET_SHOULD_REFETCH_CART_VEHICLE, payload);
    },
};

export default {
    namespaced: false,
    state,
    getters,
    mutations,
    actions,
    mutationTypes,
};
