import Intercom from '@intercom/messenger-js-sdk';

export default function ({ $config, store }) {
    if ($config.intercomEnabled && !!$config.intercomAppId) {
        const settings = {
            app_id: $config.intercomAppId,
            region: 'eu',
        };

        if (store.getters.isLoggedIn) {
            const user = store.getters.user;

            settings.user_id = user.id || null;
            settings.name = user.name || null;
            settings.email = user.email || null;
            settings.user_type = user.role || null;
            settings.user_hash = user.intercomHash || null;
        }

        Intercom(settings);
    }
}
