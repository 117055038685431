/**
 * @note Keep in sync with the list Asset::EQUIPMENT_FIELDS in the backend
 */
const equipmentFields = [
    'refrigerator',
    'freezer',
    'microwaveOven',
    'stove',
    'oven',
    'fullyEquippedKitchen',
    'hotWater',
    'shower',
    'gps',
    'airCondition',
    'airConditionDriving',
    'backupCamera',
    'auxPort',
    'bluetoothAudio',
    'parkingSensors',
    'cdPlayer',
    'childSafetySeat',
    'tv',
    'toiletType',
    'heating',
    'floorHeating',
    'blackoutCurtain',
    'blinds',
    'mosquitoNet',
    'towbar',
    'towbarSocket',
    'towbarSocketAdapter',
    'solarPanel',
    'awning',
    'awningCarpet',
    'marquis',
    'pillowsAndBlanketsIncluded',
];

export default equipmentFields;
