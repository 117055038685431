<template>
    <li v-if="type === 'checkbox' && shouldDisplayItem" class="checkbox-list-item">
        <div class="checkbox-list-item__text">
            <svg-icon name="check" class="checkbox-list-item__icon" />
            {{ formattedValue }}
        </div>
    </li>
    <div
        v-else-if="type === 'icon' && shouldDisplayItem"
        :class="['icon-list-item', { pointer: isLink && field === 'subType' }]"
        @click="iconTypeOnClick"
    >
        <svg-icon :name="iconName" :class="['icon-list-item__icon', { 'subtype-icon': field === 'subType' }]" />

        <div class="icon-list-item__text">
            <a v-if="shouldLinkToLocationPage" :href="vehicle.locationPage.url">
                {{ formattedValue }}
            </a>
            <a v-else-if="isLink" :href="getFullLinkPath()" target="_blank">
                {{ formattedValue }}
            </a>
            <template v-else>
                {{ formattedValue }}
            </template>
        </div>
    </div>
    <li v-else-if="type === 'overview' && shouldDisplayItem" class="overview-list-item">
        <label class="overview-list-item__label">{{ label }}</label>
        <a v-if="shouldLinkToBrandPage" :href="vehicle.brandPage.url">
            {{ formattedValue }}
        </a>
        <a v-else-if="isLink" :href="getFullLinkPath()" target="_blank">
            {{ formattedValue }}
        </a>
        <span v-else class="overview-list-item__value">
            {{ fieldValue || formattedValue }}
            <svg-icon v-if="tooltipText" v-tooltip="tooltipText" name="info-outline" />
        </span>
    </li>
    <!-- eslint-disable-next-line vue/valid-template-root False positive-->
    <li v-else-if="type === 'rule' && shouldDisplayItem" class="rules-list-item">
        <svg-icon name="bullet" class="rules-list__icon" />
        <span class="rules-list__text">
            {{ formattedValue }}
        </span>
    </li>
    <li v-else-if="type === 'value' && shouldDisplayItem" class="value-list-item">
        <a v-if="isLink" :href="getFullLinkPath()" target="_blank">
            {{ formattedValue }}
        </a>
        <template v-else>
            {{ formattedValue }}
        </template>
    </li>
    <div v-else-if="type === 'inline' && shouldDisplayItem" class="inline-list-item">
        {{ formattedValue }}
    </div>
</template>

<script>
import { ASSET_SUB_TYPE_MOTORHOME } from '~/config/assetTypes';
import {
    getVehicleFieldLabel,
    getVehicleFieldValue,
    getVehicleFieldFallbackValue,
    getVehicleFieldFormattedValue,
    hiddenFieldsByVehicleType,
} from '~/config/vehicleFields';

const defaultIcons = {
    city: 'location',
    sleepsNumber: 'hotel',
    sitsNumber: 'seat_belt',
    toiletType: 'toilet',
};

export default {
    props: {
        type: {
            type: String,
            default: 'checkbox', // 'checkbox', 'icon', 'overview' or 'rule'
        },
        tooltipText: {
            type: String,
            default: null,
        },
        vehicle: {
            type: Object,
            default: () => {},
        },
        field: {
            type: String,
            default: '',
        },
        // Used by type 'icon'
        icon: {
            type: String,
            default: null,
        },
        suffix: {
            type: String,
            default: null,
        },
        isLink: {
            type: Boolean,
            default: false,
        },
        fieldValue: {
            type: String,
            default: '',
        },
    },
    computed: {
        shouldDisplayItem() {
            let { subType } = this.vehicle;

            if (!subType) {
                // Fallback for assetSnapshots from before we introduced subType
                subType = ASSET_SUB_TYPE_MOTORHOME;
            }

            const isHiddenForVehicle = hiddenFieldsByVehicleType[subType].includes(this.field);

            if (isHiddenForVehicle) {
                return false;
            }

            if ((this.type === 'overview' || this.type === 'rule') && !getVehicleFieldFallbackValue(this.field)) {
                return !this.isUndefined && !this.isEmpty;
            }

            return true;
        },
        shouldLinkToLocationPage() {
            return this.field === 'city' && this.vehicle.locationPage;
        },
        shouldLinkToBrandPage() {
            return this.field === 'brand' && this.vehicle.brandPage;
        },
        isUndefined() {
            return typeof this.value === 'undefined';
        },
        isEmpty() {
            return this.value === null || this.value.length === 0;
        },
        value() {
            return getVehicleFieldValue(this.vehicle, this.field);
        },
        label() {
            return getVehicleFieldLabel(this.vehicle, this.field);
        },
        formattedValue() {
            return getVehicleFieldFormattedValue(this.vehicle, this.field);
        },
        iconName() {
            if (this.icon !== null) {
                return this.icon;
            }

            if (typeof defaultIcons[this.field] !== 'undefined') {
                return defaultIcons[this.field];
            }

            throw new Error(`No icon given and no default icon found for field: ${this.field}`);
        },
    },
    methods: {
        getFullLinkPath() {
            const { siteUrl } = this.$config;
            const { code } = this.$i18n.localeProperties;
            const locale = code === 'en' ? '' : `/${code}`;

            return `${siteUrl}${locale}${this.$utils.getVehicleSearchPagePath(this.vehicle)}`;
        },
        iconTypeOnClick() {
            if (this.isLink && this.field === 'subType') {
                return this.$router.push(
                    this.localePath({
                        path: this.$utils.getVehicleSearchPagePath(this.vehicle),
                    })
                );
            }
        },
    },
};
</script>

<style lang="scss">
@import '@/sass/_variables.scss';

.checkbox-list-item {
    margin-left: 28px;
    padding-bottom: 5px;
    @include font-size(14px);

    @include sm {
        @include font-size(16px);
        padding-bottom: 15px;
    }

    &__icon.svg-icon {
        height: 24px;
        width: 24px;
    }

    &__text {
        text-indent: -28px;
        display: inline-block;
    }
}

.icon-list-item {
    text-align: center;
    padding: 0 5px;
    display: inline-block;
    position: relative;
    max-width: 13%;
    @include font-size(14px);

    @include sm {
        @include font-size(16px);
    }

    &__icon {
        margin: auto;

        &.svg-icon {
            width: 32px;
            height: 32px;
            transition: color 0.2s;

            &.subtype-icon {
                width: 42px;
            }
        }
    }

    &__text {
        margin: 6px auto 3px auto;
        line-height: 1.3em;

        a {
            text-decoration: none;
            color: $primary;
        }
    }

    &.pointer {
        cursor: pointer;
    }
}

.overview-list-item {
    margin-bottom: 15px;
    margin-right: 5px;
    line-height: 1.3em;
    min-width: 240px;
    display: flex;
    @include font-size(14px);

    @include sm {
        @include font-size(16px);
    }

    &__label {
        font-weight: 400;
        margin-right: 10px;
        color: $secondary-text-color;
        white-space: nowrap; // Avoids breaking the labels mid-word

        &:after {
            content: ':';
        }
    }

    &__value {
        display: inline-flex;
    }

    .svg-icon {
        align-self: center;
        margin-left: 8px;
    }

    a {
        color: $primary;
    }
}

.rules-list-item {
    text-indent: -20px;
    margin: 0;
    padding-bottom: 10px;
    display: inline-block;
    width: 100%;
    line-height: 1.2;
    @include font-size(14px);

    @include sm {
        @include font-size(16px);
        padding-bottom: 15px;
    }
}

.inline-list-item {
    display: inline-block;
    @include font-size(14px);

    @include sm {
        @include font-size(16px);
    }

    &:after {
        content: '•';
        margin: 0 5px;
    }

    &:last-child:after {
        content: '';
    }
}
</style>
