// These statuses are also used in the backend
export const STATUS_DRAFT = 'draft';
export const STATUS_PENDING_VERIFICATION = 'pending_verification';
export const STATUS_PENDING_PROOF_OF_RESIDENCY = 'pending_proof_of_residency';
export const STATUS_APPROVED = 'approved';
export const STATUS_DENIED = 'denied';

export const STATUS_FAILED = 'failed';

export const EID_MODE = 'eid';
export const MANUAL_MODE = 'manual';

// These types are also used in the backend
export const TYPE_PASSPORT = 'passport';
export const TYPE_ID_CARD = 'id_card';

export const countriesExcludedFromIdVerification = ['CH', 'LI'];
