<template>
    <div class="main-menu-item" :class="{ 'has-sub-menu': hasSubMenu, 'sub-menu-open': subMenuOpen }">
        <client-only>
            <Badge v-if="warningBadge" type="warning" class="main-menu-item__badge" />
            <Badge v-else-if="badgeCount > 0" :count="badgeCount" class="main-menu-item__badge" />
        </client-only>

        <LocalizedLink :to="to" class="main-menu-item__text" :class="{ 'nuxt-link-active': isActive }">
            <slot />
        </LocalizedLink>

        <div v-if="hasSubMenu" class="main-menu-item__sub-menu-toggle" @click.prevent="toggleSubMenu">
            <a href="#" class="main-menu-item__text" :class="{ 'nuxt-link-active': isActive }"> <slot /></a>
            <svg-icon
                v-if="hasSubMenu"
                :name="subMenuOpen ? 'keyboard_arrow_up' : 'keyboard_arrow_down'"
                class="main-menu-item__sub-menu-icon"
            />
        </div>

        <div
            v-if="hasSubMenu"
            class="main-menu-item__sub-menu"
            :class="{ 'is-open': subMenuOpen, 'size-small': itemCount <= 3 }"
        >
            <div v-for="item in submenuItems" :key="item.label + item.to.name" class="sub-menu-item">
                <LocalizedLink :to="item.to">
                    {{ item.label }}
                </LocalizedLink>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { actionTypes as navigationActions } from '~/store/navigation';
import Badge from '~/components/Badge.vue';

export default {
    components: { Badge },
    props: {
        to: {
            type: [String, Object],
            default: () => {},
        },
        badgeCount: {
            type: Number,
            default: 0,
        },
        warningBadge: {
            type: Boolean,
            default: false,
        },
        // Menu item name, used for custom active menu marks
        name: {
            type: String,
            default: null,
        },
        submenuItems: {
            type: Array,
            default: null,
        },
    },
    computed: {
        ...mapGetters(['activeMenuItem', 'openSubMenu']),
        subMenuOpen() {
            return this.to.name === this.openSubMenu;
        },
        isActive() {
            return (
                this.isCustomActiveMenuItem ||
                this.submenuItemRouteNames.includes(this.getRouteBaseName()) ||
                this.isRentItemAndRentPage
            );
        },
        isCustomActiveMenuItem() {
            if (!this.activeMenuItem || !this.name) {
                return false;
            }

            return this.activeMenuItem === this.name;
        },
        isRentItemAndRentPage() {
            const isRentPage =
                this.$route.path.includes(this.$t('common.caravans_page_path')) ||
                this.$route.path.includes(this.$t('common.campervans_page_path'));

            return isRentPage && this.name === 'rent';
        },
        hasSubMenu() {
            return this.itemCount > 0;
        },
        itemCount() {
            return this.submenuItems ? this.submenuItems.length : 0;
        },
        submenuItemRouteNames() {
            if (!this.hasSubMenu) {
                return [];
            }

            return this.submenuItems.map(item => item.to.name);
        },
    },
    watch: {
        isActive() {
            if (this.isActive && !this.subMenuOpen) {
                this.$store.dispatch(navigationActions.OPEN_SUB_MENU, this.to.name);
            }
        },
    },
    mounted() {
        if (this.isActive) {
            this.$store.dispatch(navigationActions.OPEN_SUB_MENU, this.to.name);
        }
    },
    methods: {
        toggleSubMenu() {
            this.$store.dispatch(navigationActions.TOGGLE_SUB_MENU, this.to.name);
        },
    },
};
</script>

<style lang="scss">
@import '@/sass/_variables.scss';

.main-menu-item {
    position: relative;
    line-height: 26px;

    @include menu-breakpoint {
        line-height: 1.1em;
    }

    &__badge {
        position: absolute;
        top: 6px;
        left: 38px;

        @include menu-breakpoint {
            position: relative;
            margin-right: 2px;
            top: -2px;
            left: 0;
        }
    }

    &.has-sub-menu {
        .main-menu-item__text {
            display: none;
        }

        .main-menu-item__sub-menu-toggle .main-menu-item__text {
            display: initial;
        }

        @include menu-breakpoint {
            & > .main-menu-item__text {
                display: initial;
            }

            .main-menu-item__sub-menu-toggle {
                display: none;
            }
        }

        .main-menu-item__sub-menu-toggle .main-menu-item__text {
            &:hover,
            &:active {
                border-bottom-color: transparent;
            }

            @include menu-breakpoint {
                &:hover,
                &:active {
                    border-bottom-color: $primary-light;
                }
            }
        }
    }

    &__sub-menu-icon {
        color: $primary;
        width: 28px;
        height: 24px;
        margin-left: 5px;
        margin-top: -4px;

        @include menu-breakpoint {
            display: none;
        }
    }

    &__sub-menu {
        background: $cream-white-dark;
        max-height: 0;
        overflow: hidden;
        width: 100%;
        padding-left: 90px;
        box-sizing: border-box;
        text-decoration: none;
        transition: max-height 0.5s ease-in-out;
        @include font-size(14px);

        @include md {
            @include font-size(16px);
        }

        &.is-open {
            max-height: 370px; // This must be increased if we have taller submenus than this

            &.size-small {
                max-height: 120px;
            }
        }

        @include menu-breakpoint {
            display: none;

            &.is-open {
                display: none;
            }
        }

        .sub-menu-item {
            margin-top: 10px;
            &:last-child {
                margin-bottom: 10px;
            }

            a {
                font-weight: normal;
                border-bottom-width: 1px;
            }
        }
    }
}
</style>
