<template>
    <div class="vehicle-rating">
        <svg-icon :name="numOfReviews > 0 ? 'star' : 'star_border'" />
        <span>{{ rating }}</span>
    </div>
</template>

<script>
export default {
    name: 'VehicleRating',
    props: {
        value: {
            type: Number,
            default: 0,
        },
        numOfReviews: {
            type: Number,
            default: null,
        },
        showReviewsText: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        rating() {
            if (this.numOfReviews === null || this.numOfReviews === 0) {
                return this.$t('vehicle_card.no_reviews');
            }

            if (this.showReviewsText) {
                return `${this.value.toFixed(1)} (${this.$tc('ratings.count', this.numOfReviews, {
                    count: this.numOfReviews,
                })})`;
            }

            return `${this.value.toFixed(1)} (${this.numOfReviews})`;
        },
    },
};
</script>

<style lang="scss">
.vehicle-rating {
    display: flex;
    align-items: center;
    svg {
        margin-right: 4px;
        width: 20px;
        height: 20px;
    }
}
</style>
