<!-- eslint-disable @intlify/vue-i18n/no-raw-text -->
<template>
    <div
        v-if="vehicle"
        class="vehicle-location vehicle-location__wrapper"
        :class="{ 'vehicle-location--old': !isNewBookingProcessOptimisationEnabled }"
    >
        <svg-icon v-if="showIcon" name="location" />
        <template v-if="withType">
            <VehicleField
                type="value"
                class="vehicle-location__type"
                :vehicle="vehicle"
                field="subType"
                :is-link="true"
            />
            <span>&nbsp;{{ $t('common.in') }}&nbsp;</span>
        </template>
        <a v-if="vehicle.locationPage" :href="vehicle.locationPage.url" class="vehicle-location__link">
            <span v-if="vehicle.city" class="vehicle-location__city-wrapper">
                <span class="vehicle-location__city">{{ vehicle.city }}</span>
                <span v-if="vehicle.country" class="vehicle-location__comma">,</span>
            </span>
            <span v-if="vehicle.country">{{ country }}</span>
        </a>
        <template v-else>
            <span v-if="vehicle.city" class="vehicle-location__city-wrapper">
                <span class="vehicle-location__city">{{ vehicle.city }}</span>
                <span v-if="vehicle.country" class="vehicle-location__comma">,</span>
            </span>
            <span v-if="vehicle.country">{{ country }}</span>

            <i18n
                v-if="vehicle.hasOwnProperty('distance')"
                tag="span"
                class="vehicle-location__distance"
                path="vehicle_card.distance"
                >{{ vehicle.distance | rounded }}</i18n
            >
        </template>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import VehicleField from '~/components/vehicle/VehicleField';
export default {
    name: 'VehicleLocation',
    components: { VehicleField },
    props: {
        vehicle: {
            type: Object,
            required: true,
        },
        withType: {
            type: Boolean,
            default: false,
        },
        showFullCountryName: {
            type: Boolean,
            default: false,
        },
        showIcon: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        ...mapGetters(['isNewBookingProcessOptimisationEnabled']),
        country() {
            if (this.showFullCountryName) {
                return this.$t(`localization.countries.${this.vehicle.country}`);
            }

            return this.vehicle.country;
        },
    },
};
</script>

<style scoped lang="scss">
@import '@/sass/_variables.scss';

.vehicle-location {
    &__type {
        list-style: none;
    }

    &__wrapper {
        display: flex;
        overflow: hidden;
        align-items: center;

        span {
            font-weight: bold;
        }

        .svg-icon {
            width: 24px;
            height: 24px;
            min-width: 24px;
            margin: 0 4px 0 -2px;
        }
    }

    &--old {
        ::v-deep a,
        a {
            text-decoration: none;
            font-weight: bold;
            color: $primary-text-color;

            &:hover,
            &:active {
                text-decoration: underline;
            }
        }
    }

    &__link {
        display: flex;
        overflow: hidden;
    }

    &__distance {
        padding-left: 4px;
        white-space: nowrap;
    }

    &__city {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        min-width: 0;
        flex: 1;
    }

    &__city-wrapper {
        display: flex;
        align-items: center;
        min-width: 0;
    }

    &__comma {
        margin-right: 0.3em;
        flex-shrink: 0;
    }
}
</style>
