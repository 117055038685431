/* eslint-disable no-param-reassign, no-shadow */
import { PAID, PAYABLE, EXPIRED } from '~/config/installmentStatuses';
import { PENDING, SUCCEEDED } from '~/config/transferStatuses';
import { DOWN_PAYMENT, RENTAL_FEE } from '~/config/installmentTypes';
import { INSTALLMENT_PAYMENT, TOTAL, DOWN_PAYMENT as TRANSFER_DOWN_PAYMENT } from '~/config/transferTypes';
import { PAYABLE as DEPOSIT_PAYABLE, EXPIRED as DEPOSIT_EXPIRED, PAID as DEPOSIT_PAID } from '~/config/depositStatuses';
import { CANCELED } from '~/config/orderStatuses';
import OrderService from '~/services/OrderService';
import { OMOCOM_PACKAGES, PLATFORM_INSURANCE } from '~/config/productTypes';

export const mutationTypes = {
    ORDER_SET: 'ORDER/SET',
    ORDER_UNSET: 'ORDER/UNSET',
    ORDER_ITEMS_SET: 'ORDER/ORDER_ITEMS_SET',
    ORDER_ITEMS_UNSET: 'ORDER/ORDER_ITEMS_UNSET',
    ORDER_DISCOUNTS_SET: 'ORDER/ORDER_DISCOUNTS_SET',
    ORDER_DISCOUNTS_UNSET: 'ORDER/ORDER_DISCOUNTS_UNSET',
};

export const actionTypes = {
    FETCH: 'ORDER/FETCH',
    CLEAR: 'ORDER/CLEAR',
};

export const initialState = {
    order: null,
};

export const state = () => ({
    order: initialState.order,
    orderItems: [],
    orderDiscounts: [],
});

export const getters = {
    order: state => state.order,
    orderItems: state => state.orderItems,
    orderDiscounts: state => state.orderDiscounts,
    orderTransfers: (state, getters) => {
        if (!state.order) {
            return [];
        }

        const transfers = state.order.transfers ? [...state.order.transfers] : [];
        const totalTransfer = transfers.find(t => t.type === TOTAL);
        const hasTotalTransfer = typeof totalTransfer !== 'undefined';

        // Create a 'pending' transfer for any upcoming installment (except RENTAL_FEE & DOWN_PAYMENT) with owners amount > 0,
        // If no totalTransfer exists yet, any upcoming installment will be added to that transfer and thus they should not
        // be added here.
        if (
            hasTotalTransfer &&
            getters &&
            getters.nextPayableInstallment &&
            ![RENTAL_FEE, DOWN_PAYMENT].includes(getters.nextPayableInstallment.type)
        ) {
            const installment = getters.nextPayableInstallment;
            const ownersAmount = installment.ownersAmount;

            if (ownersAmount > 0) {
                transfers.push({
                    status: PENDING,
                    type: INSTALLMENT_PAYMENT,
                    amount: ownersAmount,
                    amountReversed: 0,
                    netAmount: ownersAmount,
                    currency: state.order.currency,
                    expectedAt: installment.dueAt,
                    installmentId: installment.id,
                });
            }
        }

        const downPaymentTransfer = transfers.find(t => t.type === TRANSFER_DOWN_PAYMENT);

        // Create a 'pending' transfer for the "total" if there is none from before
        if (!hasTotalTransfer && !getters.orderIsCanceled) {
            // Before the booking is confirmed there's just a down payment installment
            // Therefore, the "total" amount should be order total less down payment amount
            let ownersAmount = state.order.ownersTotal;

            if (downPaymentTransfer) {
                ownersAmount -= downPaymentTransfer.netAmount;
            }

            transfers.push({
                status: PENDING,
                type: TOTAL,
                amount: ownersAmount,
                amountReversed: 0,
                netAmount: ownersAmount,
                currency: state.order.currency,
                timestamp: getters && getters.booking ? getters.booking.checkin : null,
            });
        }

        return transfers.filter(t => [PENDING, SUCCEEDED].includes(t.status)).filter(t => t.netAmount > 0);
    },
    orderInstallments: state => {
        if (!state.order) {
            return [];
        }

        const { installments } = state.order;

        if (!Array.isArray(installments)) {
            return [];
        }

        return installments.filter(i => [EXPIRED, PAYABLE, PAID].includes(i.status));
    },
    hasPayableInstallment: (state, getters) => {
        return (
            (getters.booking && getters.booking.orderDetails && !!getters.booking.orderDetails.hasPayableInstallment) ||
            getters.nextPayableInstallment !== null
        );
    },
    nextPayableInstallment: state => {
        if (!state.order) {
            return null;
        }

        const { installments } = state.order;

        if (!Array.isArray(installments) || installments.length === 0) {
            return null;
        }

        let payable = installments.find(i => i.status === PAYABLE);

        if (!payable) {
            payable = installments.find(i => i.status === EXPIRED);
        }

        if (!payable) {
            // find returns 'undefined' of no match
            return null;
        }

        return payable;
    },
    orderRentalFeeFullyPaid: (_state, getters) =>
        getters.orderInstallments
            .filter(installment => installment.type === RENTAL_FEE)
            .some(installment => installment.status === PAID),
    orderAllowsDeductibleBuydownPurchase: (state, getters) => {
        const installment = getters.nextPayableInstallment;

        // Should not allow it if there's a payable installment which is not the DOWN_PAYMENT or RENTAL_FEE
        return !installment || [DOWN_PAYMENT, RENTAL_FEE].includes(installment.type);
    },
    orderIsCanceled(state) {
        if (!state.order) {
            return false;
        }

        return state.order.status === CANCELED;
    },
    deposit(state) {
        if (!state.order || !state.order.deposit) {
            return null;
        }

        return state.order.deposit;
    },
    hasDeposit(_state, getters) {
        return !!getters.deposit;
    },
    depositIsPaid(state) {
        if (!state.order || !state.order.deposit) {
            return false;
        }

        return state.order.deposit.status === DEPOSIT_PAID;
    },
    depositIsPayable(state) {
        if (!state.order || !state.order.deposit) {
            return false;
        }

        return [DEPOSIT_PAYABLE, DEPOSIT_EXPIRED].includes(state.order.deposit.status);
    },
    depositDeadline(state) {
        if (!state.order || !state.order.deposit) {
            return null;
        }

        return state.order.deposit.dueAt;
    },
    depositAmount(state) {
        if (!state.order || !state.order.deposit) {
            return 0;
        }

        return state.order.deposit.amount || 0;
    },
    deductibleAmount(state) {
        if (!state.order || !state.order.deposit) {
            return 0;
        }

        return state.order.deposit.deductibleAmount || 0;
    },
    depositAndDeductibleAmount(state, getters) {
        return getters.depositAmount + getters.deductibleAmount;
    },
    isDepositReservable(state) {
        if (!state.order || !state.order.deposit) {
            return false;
        }

        return state.order.deposit.isReservable;
    },
    remainingDepositAmount(state) {
        if (!state.order || !state.order.deposit) {
            return 0;
        }

        return state.order.deposit.remainingDepositAmount;
    },
    paidOutDepositAmount(state) {
        if (!state.order || !state.order.deposit) {
            return 0;
        }

        return state.order.deposit.paidOutAmount;
    },
    currentPlatformInsuranceOrderItem(state, getters) {
        if (!state.order) {
            return null;
        }

        return getters.orderItems.find(item => item.productType === PLATFORM_INSURANCE);
    },
    currentOmocomInsuranceProduct(state, getters) {
        if (!getters.isOmocomInsurance || !getters.currentPlatformInsuranceOrderItem) {
            return null;
        }

        const product = getters.currentPlatformInsuranceOrderItem.product;

        return product && OMOCOM_PACKAGES.includes(product.key) ? product : null;
    },
};

export const mutations = {
    [mutationTypes.ORDER_SET](state, order) {
        state.order = { ...order };
    },
    [mutationTypes.ORDER_UNSET](state) {
        state.order = initialState.order;
    },
    [mutationTypes.ORDER_ITEMS_SET](state, items) {
        state.orderItems = items;
    },
    [mutationTypes.ORDER_ITEMS_UNSET](state) {
        state.orderItems = [];
    },
    [mutationTypes.ORDER_DISCOUNTS_SET](state, discounts) {
        state.orderDiscounts = discounts;
    },
    [mutationTypes.ORDER_DISCOUNTS_UNSET](state) {
        state.orderDiscounts = [];
    },
};

export const actions = {
    async [actionTypes.FETCH]({ commit }, orderId) {
        const orderService = new OrderService(this);

        const [order, items, discounts] = await Promise.all([
            orderService.get(orderId),
            orderService.getOrderItems(orderId),
            orderService.getOrderDiscounts(orderId),
        ]);

        commit(mutationTypes.ORDER_SET, order);
        commit(mutationTypes.ORDER_ITEMS_SET, items);
        commit(mutationTypes.ORDER_DISCOUNTS_SET, discounts);
    },
    [actionTypes.CLEAR]({ commit }) {
        commit(mutationTypes.ORDER_UNSET);
        commit(mutationTypes.ORDER_ITEMS_UNSET);
        commit(mutationTypes.ORDER_DISCOUNTS_UNSET);
    },
};

export default {
    namespaced: false,
    state,
    getters,
    mutations,
    actions,
    mutationTypes,
};
