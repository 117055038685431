import { loadStripe } from '@stripe/stripe-js/pure';

const clients = {
    stripe_se: null,
    stripe_no: null,
    stripe_fi: null,
    stripe_dk: null,
    stripe_ch: null,
};
export default class StripeService {
    constructor({ $axios, i18n, $config }) {
        this.$axios = $axios;
        this.$i18n = i18n;
        this.$config = $config;

        // Remember last used account to avoid recreating client unnecessarily
        this.currentStripeAccount = null;
    }

    withStripeAccount(stripeAccount) {
        if (clients[stripeAccount] !== null) {
            return Promise.resolve(this);
        }

        return loadStripe(this.$config.stripe[stripeAccount].apiKey, {
            locale: this.$i18n.localeProperties.iso.slice(0, 2),
        }).then(client => {
            clients[stripeAccount] = client;

            this.currentStripeAccount = stripeAccount;

            return this;
        });
    }

    getClient() {
        if (this.currentStripeAccount === null || clients[this.currentStripeAccount] === null) {
            throw new Error(
                'StripeService method called without initiated client (missing preceding withStripeAccount() call?)'
            );
        }

        return clients[this.currentStripeAccount];
    }

    elements(options = {}) {
        return this.getClient().elements(options);
    }

    createPaymentMethodForElements(options) {
        return new Promise((resolve, reject) => {
            this.getClient()
                .createPaymentMethod(options)
                .then(stripeResponse => {
                    if (stripeResponse.error) {
                        reject(stripeResponse.error);

                        return;
                    }

                    const { paymentMethod } = stripeResponse;
                    resolve(paymentMethod);
                });
        });
    }

    /**
     *
     * @param {string} clientSecret Stripe client secret
     * @param {object} confirmParams Can contain returnUrl param
     * @param {boolean} redirectIfRequired
     * @returns {Promise<PaymentIntent>}
     */
    confirmPayment(clientSecret, confirmParams = {}, redirectIfRequired = true) {
        return new Promise((resolve, reject) => {
            const options = { clientSecret, confirmParams };

            if (redirectIfRequired) {
                options.redirect = 'if_required';
            }

            this.getClient()
                .confirmPayment(options)
                .then(stripeResponse => {
                    if (stripeResponse.error) {
                        reject(stripeResponse.error);

                        return;
                    }

                    resolve(stripeResponse.paymentIntent);
                });
        });
    }

    createToken(type, data) {
        return new Promise((resolve, reject) => {
            this.getClient()
                .createToken(type, data)
                .then(stripeResponse => {
                    if (stripeResponse.error) {
                        reject(stripeResponse.error);

                        return;
                    }

                    const { token } = stripeResponse;

                    resolve(token);
                });
        });
    }

    /**
     * The following methods only interact with our API
     */
    createAccountLink(userId, refreshUrl, returnUrl) {
        const config = {
            method: 'post',
            url: '/stripe/account-links',
            data: {
                userId,
                refreshUrl,
                returnUrl,
            },
        };

        return this.$axios(config).then(response => response.data.data);
    }
}
