import { update, shutdown, boot } from '@intercom/messenger-js-sdk';

export default {
    watch: {
        isLoggedIn(newValue) {
            if (!this.$config.intercomEnabled) {
                return;
            }

            if (newValue) {
                const user = this.$store.getters.user;

                update({
                    user_id: user.id,
                    name: user.name,
                    email: user.email,
                    user_type: user.role,
                    user_hash: user.intercomHash,
                });
            } else {
                shutdown();

                boot({
                    app_id: this.$config.intercomAppId,
                    region: 'eu',
                });
            }
        },
    },
};
