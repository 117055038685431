<template>
    <div class="slide-up-modal" :class="slideUpModalModifierClasses" :style="cssVariables">
        <div
            v-closable="{
                handler: 'closeModal',
                exclude: ['.slide-up-modal__wrapper'],
            }"
            class="slide-up-modal__mask"
            @click="closeModal"
        />
        <div class="slide-up-modal__wrapper">
            <div class="slide-up-modal__header">
                <a @click="closeModal">
                    <svg-icon name="close" />
                </a>
            </div>

            <div ref="slideUpModalContent" class="slide-up-modal__content">
                <component :is="config.component" :data="config.data" />
            </div>
        </div>
    </div>
</template>

<script>
import { clearAllBodyScrollLocks, disableBodyScroll } from 'body-scroll-lock';
import { actionTypes as slideUpModalActions } from '~/store/slideUpModal';

export default {
    name: 'SlideUpModal',
    components: {
        Test: () => import('~/components/modals/NewsletterModal.vue'),
        PriceDetailsModal: () => import('~/components/modals/PriceDetailsModal.vue'),
        BookingDetailsSlideUpModal: () => import('~/components/modals/BookingDetailsSlideUpModal.vue'),
    },
    props: {
        config: {
            type: Object,
            default: () => {},
        },
    },
    data() {
        return {
            isModalOpen: false,
            animationDuration: 300,
        };
    },
    computed: {
        options() {
            const defaultConfig = {
                fixToMaxHeight: false,
            };

            const options = { ...defaultConfig, ...this.config };

            return options;
        },
        cssVariables() {
            return {
                '--slide-up-modal-animation-duration': `${this.animationDuration}ms`,
            };
        },
        slideUpModalModifierClasses() {
            return {
                'slide-up-modal--visible': this.isModalOpen,
                'slide-up-modal--fix-to-max-height': this.options.fixToMaxHeight,
            };
        },
    },
    mounted() {
        this.openModal();
    },
    methods: {
        openModal() {
            this.$nextTick(() => {
                this.isModalOpen = true;
                disableBodyScroll(this.$refs.slideUpModalContent);
            });
        },
        closeModal() {
            this.isModalOpen = false;

            setTimeout(() => {
                clearAllBodyScrollLocks();

                this.config.reject();
                this.$store.dispatch(slideUpModalActions.SLIDEUP_MODAL_CLOSE);
            }, this.animationDuration);
        },
    },
};
</script>

<style scoped lang="scss">
@import '~/sass/_variables.scss';

$max-height: 85vh;

.slide-up-modal {
    &--visible {
        .slide-up-modal__wrapper {
            transform: translateY(0);
        }

        .slide-up-modal__mask {
            opacity: 1;
        }
    }

    &__mask {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: $z-modal;
        background-color: rgba(0, 0, 0, 0.25);
        opacity: 0;
        transition: opacity var(--slide-up-modal-animation-duration) ease-in-out;
    }

    &__wrapper {
        position: fixed;
        bottom: 0;
        width: 100%;
        max-height: $max-height;
        display: flex;
        flex-direction: column;
        z-index: $z-modal;
        border-radius: 16px 16px 0 0;
        padding: 24px 24px 40px;
        transform: translateY(100%);
        transition: transform var(--slide-up-modal-animation-duration) ease-in-out;
        box-sizing: border-box;
        background: white;
    }

    &--fix-to-max-height {
        .slide-up-modal__wrapper {
            height: $max-height;
        }
    }

    &__header {
        display: flex;
        justify-content: flex-end;
        margin-bottom: 8px;

        .svg-icon {
            width: 24px;
            height: 24px;
            color: $primary;
        }
    }

    &__content {
        overflow-y: auto;
        display: flex;
        justify-content: center;
        text-align: center;

        @include md {
            padding: 0 100px;
        }
    }
}
</style>
