import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _b735053e = () => interopDefault(import('../pages/elements.vue' /* webpackChunkName: "pages/elements" */))
const _0e433b93 = () => interopDefault(import('../pages/about.vue' /* webpackChunkName: "pages/about" */))
const _7b3ab9a5 = () => interopDefault(import('../pages/renter-about-me.vue' /* webpackChunkName: "pages/renter-about-me" */))
const _83dfe29a = () => interopDefault(import('../pages/account.vue' /* webpackChunkName: "pages/account" */))
const _9aacfe96 = () => interopDefault(import('../pages/bookings/booking-history.vue' /* webpackChunkName: "pages/bookings/booking-history" */))
const _725241a7 = () => interopDefault(import('../pages/bookings/index.vue' /* webpackChunkName: "pages/bookings/index" */))
const _4ee290f8 = () => interopDefault(import('../pages/company-details.vue' /* webpackChunkName: "pages/company-details" */))
const _78030dd9 = () => interopDefault(import('../pages/owner-complete-profile.vue' /* webpackChunkName: "pages/owner-complete-profile" */))
const _24c6c615 = () => interopDefault(import('../pages/cookies.vue' /* webpackChunkName: "pages/cookies" */))
const _21a10825 = () => interopDefault(import('../pages/renter-signup.vue' /* webpackChunkName: "pages/renter-signup" */))
const _2dfb1658 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _5e231a44 = () => interopDefault(import('../pages/economy.vue' /* webpackChunkName: "pages/economy" */))
const _82585e26 = () => interopDefault(import('../pages/economy/economy-settings.vue' /* webpackChunkName: "pages/economy/economy-settings" */))
const _0784f073 = () => interopDefault(import('../pages/economy/payout-reports.vue' /* webpackChunkName: "pages/economy/payout-reports" */))
const _6847fe76 = () => interopDefault(import('../pages/economy/payouts/index.vue' /* webpackChunkName: "pages/economy/payouts/index" */))
const _fb8b5b9a = () => interopDefault(import('../pages/economy/payouts/_payoutId.vue' /* webpackChunkName: "pages/economy/payouts/_payoutId" */))
const _46adcab0 = () => interopDefault(import('../pages/edit-vehicles.vue' /* webpackChunkName: "pages/edit-vehicles" */))
const _8c6d3346 = () => interopDefault(import('../pages/favorites.vue' /* webpackChunkName: "pages/favorites" */))
const _77c48004 = () => interopDefault(import('../pages/getting-started.vue' /* webpackChunkName: "pages/getting-started" */))
const _5543de20 = () => interopDefault(import('../pages/gift-cards.vue' /* webpackChunkName: "pages/gift-cards" */))
const _6804a7e3 = () => interopDefault(import('../pages/insurance/index.vue' /* webpackChunkName: "pages/insurance/index" */))
const _10ba8d22 = () => interopDefault(import('../pages/login.vue' /* webpackChunkName: "pages/login" */))
const _4d9321a8 = () => interopDefault(import('../pages/login-as-user.vue' /* webpackChunkName: "pages/login-as-user" */))
const _54b0c181 = () => interopDefault(import('../pages/magazine/index.vue' /* webpackChunkName: "pages/magazine/index" */))
const _40fef8b6 = () => interopDefault(import('../pages/oauth-callback.vue' /* webpackChunkName: "pages/oauth-callback" */))
const _d9c1a60e = () => interopDefault(import('../pages/our-partners/index.vue' /* webpackChunkName: "pages/our-partners/index" */))
const _638662db = () => interopDefault(import('../pages/owner-verify-id.vue' /* webpackChunkName: "pages/owner-verify-id" */))
const _dae86298 = () => interopDefault(import('../pages/mc-privacy-policy.vue' /* webpackChunkName: "pages/mc-privacy-policy" */))
const _d5f21fa2 = () => interopDefault(import('../pages/profile.vue' /* webpackChunkName: "pages/profile" */))
const _52f6044c = () => interopDefault(import('../pages/owner-publish-vehicle.vue' /* webpackChunkName: "pages/owner-publish-vehicle" */))
const _18055da4 = () => interopDefault(import('../pages/rent-out.vue' /* webpackChunkName: "pages/rent-out" */))
const _5e1072de = () => interopDefault(import('../pages/restips.vue' /* webpackChunkName: "pages/restips" */))
const _46ec5cbe = () => interopDefault(import('../pages/reviews.vue' /* webpackChunkName: "pages/reviews" */))
const _0406ba31 = () => interopDefault(import('../pages/sign-up.vue' /* webpackChunkName: "pages/sign-up" */))
const _07f8a58a = () => interopDefault(import('../pages/sitemap.vue' /* webpackChunkName: "pages/sitemap" */))
const _52e40422 = () => interopDefault(import('../pages/user-terms.vue' /* webpackChunkName: "pages/user-terms" */))
const _6a65d172 = () => interopDefault(import('../pages/renter-verify-id.vue' /* webpackChunkName: "pages/renter-verify-id" */))
const _0b9f348a = () => interopDefault(import('../pages/insurance/baloise.vue' /* webpackChunkName: "pages/insurance/baloise" */))
const _823f0c62 = () => interopDefault(import('../pages/insurance/omocom.vue' /* webpackChunkName: "pages/insurance/omocom" */))
const _2b8dfe6c = () => interopDefault(import('../pages/insurance/owners-insurance.vue' /* webpackChunkName: "pages/insurance/owners-insurance" */))
const _e8a7430a = () => interopDefault(import('../pages/our-partners/amag.vue' /* webpackChunkName: "pages/our-partners/amag" */))
const _8064d774 = () => interopDefault(import('../pages/our-partners/caravan4rep.vue' /* webpackChunkName: "pages/our-partners/caravan4rep" */))
const _3b68d856 = () => interopDefault(import('../pages/our-partners/ferrycenter.vue' /* webpackChunkName: "pages/our-partners/ferrycenter" */))
const _15102a20 = () => interopDefault(import('../pages/our-partners/myclimate.vue' /* webpackChunkName: "pages/our-partners/myclimate" */))
const _5222573e = () => interopDefault(import('../pages/owner/dashboard.vue' /* webpackChunkName: "pages/owner/dashboard" */))
const _098e1536 = () => interopDefault(import('../pages/owner/top-owner.vue' /* webpackChunkName: "pages/owner/top-owner" */))
const _4ec86fcc = () => interopDefault(import('../pages/magazine/category/_slug.vue' /* webpackChunkName: "pages/magazine/category/_slug" */))
const _aaf5eef8 = () => interopDefault(import('../pages/magazine/tag/_slug.vue' /* webpackChunkName: "pages/magazine/tag/_slug" */))
const _0d543112 = () => interopDefault(import('../pages/bookings/_id/index.vue' /* webpackChunkName: "pages/bookings/_id/index" */))
const _6b619cfe = () => interopDefault(import('../pages/edit-vehicle/_id.vue' /* webpackChunkName: "pages/edit-vehicle/_id" */))
const _237df477 = () => interopDefault(import('../pages/edit-vehicle/_id/activation.vue' /* webpackChunkName: "pages/edit-vehicle/_id/activation" */))
const _3e33e008 = () => interopDefault(import('../pages/edit-vehicle/_id/availability.vue' /* webpackChunkName: "pages/edit-vehicle/_id/availability" */))
const _45d28a5d = () => interopDefault(import('../pages/edit-vehicle/_id/basic.vue' /* webpackChunkName: "pages/edit-vehicle/_id/basic" */))
const _74d46fa4 = () => interopDefault(import('../pages/edit-vehicle/_id/cancellation.vue' /* webpackChunkName: "pages/edit-vehicle/_id/cancellation" */))
const _bd5d98f2 = () => interopDefault(import('../pages/edit-vehicle/_id/checkin-checkout.vue' /* webpackChunkName: "pages/edit-vehicle/_id/checkin-checkout" */))
const _3cea15eb = () => interopDefault(import('../pages/edit-vehicle/_id/description.vue' /* webpackChunkName: "pages/edit-vehicle/_id/description" */))
const _b6ca6f86 = () => interopDefault(import('../pages/edit-vehicle/_id/equipment.vue' /* webpackChunkName: "pages/edit-vehicle/_id/equipment" */))
const _6529c159 = () => interopDefault(import('../pages/edit-vehicle/_id/images.vue' /* webpackChunkName: "pages/edit-vehicle/_id/images" */))
const _b897f8ee = () => interopDefault(import('../pages/edit-vehicle/_id/insurance.vue' /* webpackChunkName: "pages/edit-vehicle/_id/insurance" */))
const _35b9ec54 = () => interopDefault(import('../pages/edit-vehicle/_id/location.vue' /* webpackChunkName: "pages/edit-vehicle/_id/location" */))
const _2f1eff96 = () => interopDefault(import('../pages/edit-vehicle/_id/pricing.vue' /* webpackChunkName: "pages/edit-vehicle/_id/pricing" */))
const _2f7372a6 = () => interopDefault(import('../pages/edit-vehicle/_id/rules.vue' /* webpackChunkName: "pages/edit-vehicle/_id/rules" */))
const _7c9fe4c0 = () => interopDefault(import('../pages/vehicle/_id/index.vue' /* webpackChunkName: "pages/vehicle/_id/index" */))
const _4e4c09e2 = () => interopDefault(import('../pages/login-as-user-from-admin/_id/index.vue' /* webpackChunkName: "pages/login-as-user-from-admin/_id/index" */))
const _52fa2039 = () => interopDefault(import('../pages/magazine/_slug.vue' /* webpackChunkName: "pages/magazine/_slug" */))
const _202b5344 = () => interopDefault(import('../pages/messages/_conversationId.vue' /* webpackChunkName: "pages/messages/_conversationId" */))
const _8c845e38 = () => interopDefault(import('../pages/owner/_id.vue' /* webpackChunkName: "pages/owner/_id" */))
const _56eec2d9 = () => interopDefault(import('../pages/reset-password/_resetCode.vue' /* webpackChunkName: "pages/reset-password/_resetCode" */))
const _bb188bf6 = () => interopDefault(import('../pages/verify-email/_inputCode.vue' /* webpackChunkName: "pages/verify-email/_inputCode" */))
const _4ae5a8ed = () => interopDefault(import('../pages/bookings/_id/checkin.vue' /* webpackChunkName: "pages/bookings/_id/checkin" */))
const _5ee07d36 = () => interopDefault(import('../pages/bookings/_id/checkout.vue' /* webpackChunkName: "pages/bookings/_id/checkout" */))
const _66faf94e = () => interopDefault(import('../pages/vehicle/_id/book/index.vue' /* webpackChunkName: "pages/vehicle/_id/book/index" */))
const _bb89062e = () => interopDefault(import('../pages/vehicle/_id/book/confirmation.vue' /* webpackChunkName: "pages/vehicle/_id/book/confirmation" */))
const _71655db5 = () => interopDefault(import('../pages/vehicle/_id/book/purchase.vue' /* webpackChunkName: "pages/vehicle/_id/book/purchase" */))
const _aa7dd0f8 = () => interopDefault(import('../pages/vehicle/_id/book/error.vue' /* webpackChunkName: "pages/vehicle/_id/book/error" */))
const _30a29c65 = () => interopDefault(import('../pages/vehicle/_id/book/login.vue' /* webpackChunkName: "pages/vehicle/_id/book/login" */))
const _6e8f2a3f = () => interopDefault(import('../pages/vehicle/_id/book/customize.vue' /* webpackChunkName: "pages/vehicle/_id/book/customize" */))
const _6cc9644a = () => interopDefault(import('../pages/vehicle/_id/book/about-you.vue' /* webpackChunkName: "pages/vehicle/_id/book/about-you" */))
const _451178e8 = () => interopDefault(import('../pages/vehicle/_id/book/review.vue' /* webpackChunkName: "pages/vehicle/_id/book/review" */))
const _f8e3bd54 = () => interopDefault(import('../pages/vehicle/_id/book/insurance.vue' /* webpackChunkName: "pages/vehicle/_id/book/insurance" */))
const _2efaf336 = () => interopDefault(import('../pages/_.vue' /* webpackChunkName: "pages/_" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/_elements",
    component: _b735053e,
    pathToRegexpOptions: {"strict":true},
    name: "elements___en"
  }, {
    path: "/about-us",
    component: _0e433b93,
    pathToRegexpOptions: {"strict":true},
    name: "about___en"
  }, {
    path: "/about-you",
    component: _7b3ab9a5,
    pathToRegexpOptions: {"strict":true},
    name: "renter-about-me___en"
  }, {
    path: "/account",
    component: _83dfe29a,
    pathToRegexpOptions: {"strict":true},
    name: "account___en"
  }, {
    path: "/booking-history",
    component: _9aacfe96,
    pathToRegexpOptions: {"strict":true},
    name: "bookings-booking-history___en"
  }, {
    path: "/bookings",
    component: _725241a7,
    pathToRegexpOptions: {"strict":true},
    name: "bookings___en"
  }, {
    path: "/company-details",
    component: _4ee290f8,
    pathToRegexpOptions: {"strict":true},
    name: "company-details___en"
  }, {
    path: "/complete-profile-owner",
    component: _78030dd9,
    pathToRegexpOptions: {"strict":true},
    name: "owner-complete-profile___en"
  }, {
    path: "/cookies",
    component: _24c6c615,
    pathToRegexpOptions: {"strict":true},
    name: "cookies___en"
  }, {
    path: "/create-account-renter",
    component: _21a10825,
    pathToRegexpOptions: {"strict":true},
    name: "renter-signup___en"
  }, {
    path: "/de",
    component: _2dfb1658,
    meta: {"hideGettingStartedProgressBar":true},
    pathToRegexpOptions: {"strict":true},
    name: "index___de"
  }, {
    path: "/dk",
    component: _2dfb1658,
    meta: {"hideGettingStartedProgressBar":true},
    pathToRegexpOptions: {"strict":true},
    name: "index___dk"
  }, {
    path: "/economy",
    component: _5e231a44,
    pathToRegexpOptions: {"strict":true},
    name: "economy___en",
    children: [{
      path: "economy-settings",
      component: _82585e26,
      pathToRegexpOptions: {"strict":true},
      name: "economy-settings___en"
    }, {
      path: "payout-reports",
      component: _0784f073,
      pathToRegexpOptions: {"strict":true},
      name: "payout-reports___en"
    }, {
      path: "payouts",
      component: _6847fe76,
      pathToRegexpOptions: {"strict":true},
      name: "payouts___en"
    }, {
      path: "payouts/:payoutId",
      component: _fb8b5b9a,
      pathToRegexpOptions: {"strict":true},
      name: "payout___en"
    }]
  }, {
    path: "/edit-vehicles",
    component: _46adcab0,
    pathToRegexpOptions: {"strict":true},
    name: "edit-vehicles___en"
  }, {
    path: "/favorites",
    component: _8c6d3346,
    pathToRegexpOptions: {"strict":true},
    name: "favorites___en"
  }, {
    path: "/fi",
    component: _2dfb1658,
    meta: {"hideGettingStartedProgressBar":true},
    pathToRegexpOptions: {"strict":true},
    name: "index___fi"
  }, {
    path: "/fi-sv",
    component: _2dfb1658,
    meta: {"hideGettingStartedProgressBar":true},
    pathToRegexpOptions: {"strict":true},
    name: "index___fi-sv"
  }, {
    path: "/getting-started",
    component: _77c48004,
    pathToRegexpOptions: {"strict":true},
    name: "getting-started___en"
  }, {
    path: "/gift-cards",
    component: _5543de20,
    pathToRegexpOptions: {"strict":true},
    name: "gift-cards___en"
  }, {
    path: "/insurance",
    component: _6804a7e3,
    pathToRegexpOptions: {"strict":true},
    name: "insurance___en"
  }, {
    path: "/login",
    component: _10ba8d22,
    pathToRegexpOptions: {"strict":true},
    name: "login___en"
  }, {
    path: "/login-as-user",
    component: _4d9321a8,
    pathToRegexpOptions: {"strict":true},
    name: "login-as-user___en"
  }, {
    path: "/magazine",
    component: _54b0c181,
    meta: {"hideGettingStartedProgressBar":true},
    pathToRegexpOptions: {"strict":true},
    name: "magazine___en"
  }, {
    path: "/no",
    component: _2dfb1658,
    meta: {"hideGettingStartedProgressBar":true},
    pathToRegexpOptions: {"strict":true},
    name: "index___no"
  }, {
    path: "/oauth-callback",
    component: _40fef8b6,
    pathToRegexpOptions: {"strict":true},
    name: "oauth-callback___en"
  }, {
    path: "/our-partners",
    component: _d9c1a60e,
    pathToRegexpOptions: {"strict":true},
    name: "our-partners___en"
  }, {
    path: "/owner-verify-id",
    component: _638662db,
    pathToRegexpOptions: {"strict":true},
    name: "owner-verify-id___en"
  }, {
    path: "/privacy-policy",
    component: _dae86298,
    pathToRegexpOptions: {"strict":true},
    name: "privacy-policy___en"
  }, {
    path: "/profile",
    component: _d5f21fa2,
    pathToRegexpOptions: {"strict":true},
    name: "profile___en"
  }, {
    path: "/publish-vehicle",
    component: _52f6044c,
    pathToRegexpOptions: {"strict":true},
    name: "owner-publish-vehicle___en"
  }, {
    path: "/rent-out-motorhome-caravan",
    component: _18055da4,
    meta: {"hideGettingStartedProgressBar":true},
    pathToRegexpOptions: {"strict":true},
    name: "rent-out___en"
  }, {
    path: "/restips",
    component: _5e1072de,
    pathToRegexpOptions: {"strict":true},
    name: "restips___en"
  }, {
    path: "/reviews",
    component: _46ec5cbe,
    pathToRegexpOptions: {"strict":true},
    name: "reviews___en"
  }, {
    path: "/se",
    component: _2dfb1658,
    meta: {"hideGettingStartedProgressBar":true},
    pathToRegexpOptions: {"strict":true},
    name: "index___se"
  }, {
    path: "/sign-up",
    component: _0406ba31,
    pathToRegexpOptions: {"strict":true},
    name: "sign-up___en"
  }, {
    path: "/sitemap",
    component: _07f8a58a,
    pathToRegexpOptions: {"strict":true},
    name: "sitemap___en"
  }, {
    path: "/user-terms",
    component: _52e40422,
    pathToRegexpOptions: {"strict":true},
    name: "user-terms___en"
  }, {
    path: "/verify-id",
    component: _6a65d172,
    pathToRegexpOptions: {"strict":true},
    name: "renter-verify-id___en"
  }, {
    path: "/de/_elements",
    component: _b735053e,
    pathToRegexpOptions: {"strict":true},
    name: "elements___de"
  }, {
    path: "/de/about-you",
    component: _7b3ab9a5,
    pathToRegexpOptions: {"strict":true},
    name: "renter-about-me___de"
  }, {
    path: "/de/account",
    component: _83dfe29a,
    pathToRegexpOptions: {"strict":true},
    name: "account___de"
  }, {
    path: "/de/agbs",
    component: _52e40422,
    pathToRegexpOptions: {"strict":true},
    name: "user-terms___de"
  }, {
    path: "/de/booking-history",
    component: _9aacfe96,
    pathToRegexpOptions: {"strict":true},
    name: "bookings-booking-history___de"
  }, {
    path: "/de/bookings",
    component: _725241a7,
    pathToRegexpOptions: {"strict":true},
    name: "bookings___de"
  }, {
    path: "/de/company-details",
    component: _4ee290f8,
    pathToRegexpOptions: {"strict":true},
    name: "company-details___de"
  }, {
    path: "/de/cookies",
    component: _24c6c615,
    pathToRegexpOptions: {"strict":true},
    name: "cookies___de"
  }, {
    path: "/de/create-account-renter",
    component: _21a10825,
    pathToRegexpOptions: {"strict":true},
    name: "renter-signup___de"
  }, {
    path: "/de/datenschutzbestimmungen",
    component: _dae86298,
    pathToRegexpOptions: {"strict":true},
    name: "privacy-policy___de"
  }, {
    path: "/de/economy",
    component: _5e231a44,
    pathToRegexpOptions: {"strict":true},
    name: "economy___de",
    children: [{
      path: "economy-settings",
      component: _82585e26,
      pathToRegexpOptions: {"strict":true},
      name: "economy-settings___de"
    }, {
      path: "payout-reports",
      component: _0784f073,
      pathToRegexpOptions: {"strict":true},
      name: "payout-reports___de"
    }, {
      path: "payouts",
      component: _6847fe76,
      pathToRegexpOptions: {"strict":true},
      name: "payouts___de"
    }, {
      path: "payouts/:payoutId",
      component: _fb8b5b9a,
      pathToRegexpOptions: {"strict":true},
      name: "payout___de"
    }]
  }, {
    path: "/de/edit-vehicles",
    component: _46adcab0,
    pathToRegexpOptions: {"strict":true},
    name: "edit-vehicles___de"
  }, {
    path: "/de/favorites",
    component: _8c6d3346,
    pathToRegexpOptions: {"strict":true},
    name: "favorites___de"
  }, {
    path: "/de/getting-started",
    component: _77c48004,
    pathToRegexpOptions: {"strict":true},
    name: "getting-started___de"
  }, {
    path: "/de/gutscheine",
    component: _5543de20,
    pathToRegexpOptions: {"strict":true},
    name: "gift-cards___de"
  }, {
    path: "/de/login",
    component: _10ba8d22,
    pathToRegexpOptions: {"strict":true},
    name: "login___de"
  }, {
    path: "/de/login-as-user",
    component: _4d9321a8,
    pathToRegexpOptions: {"strict":true},
    name: "login-as-user___de"
  }, {
    path: "/de/magazin",
    component: _54b0c181,
    meta: {"hideGettingStartedProgressBar":true},
    pathToRegexpOptions: {"strict":true},
    name: "magazine___de"
  }, {
    path: "/de/oauth-callback",
    component: _40fef8b6,
    pathToRegexpOptions: {"strict":true},
    name: "oauth-callback___de"
  }, {
    path: "/de/our-partners",
    component: _d9c1a60e,
    pathToRegexpOptions: {"strict":true},
    name: "our-partners___de"
  }, {
    path: "/de/owner-verify-id",
    component: _638662db,
    pathToRegexpOptions: {"strict":true},
    name: "owner-verify-id___de"
  }, {
    path: "/de/profil-vervollstaendigen-vermieter",
    component: _78030dd9,
    pathToRegexpOptions: {"strict":true},
    name: "owner-complete-profile___de"
  }, {
    path: "/de/profile",
    component: _d5f21fa2,
    pathToRegexpOptions: {"strict":true},
    name: "profile___de"
  }, {
    path: "/de/publish-vehicle",
    component: _52f6044c,
    pathToRegexpOptions: {"strict":true},
    name: "owner-publish-vehicle___de"
  }, {
    path: "/de/restips",
    component: _5e1072de,
    pathToRegexpOptions: {"strict":true},
    name: "restips___de"
  }, {
    path: "/de/reviews",
    component: _46ec5cbe,
    pathToRegexpOptions: {"strict":true},
    name: "reviews___de"
  }, {
    path: "/de/sign-up",
    component: _0406ba31,
    pathToRegexpOptions: {"strict":true},
    name: "sign-up___de"
  }, {
    path: "/de/sitemap",
    component: _07f8a58a,
    pathToRegexpOptions: {"strict":true},
    name: "sitemap___de"
  }, {
    path: "/de/ueber-uns",
    component: _0e433b93,
    pathToRegexpOptions: {"strict":true},
    name: "about___de"
  }, {
    path: "/de/verify-id",
    component: _6a65d172,
    pathToRegexpOptions: {"strict":true},
    name: "renter-verify-id___de"
  }, {
    path: "/de/versicherung",
    component: _6804a7e3,
    pathToRegexpOptions: {"strict":true},
    name: "insurance___de"
  }, {
    path: "/de/wohnmobil-wohnwagen-vermieten",
    component: _18055da4,
    meta: {"hideGettingStartedProgressBar":true},
    pathToRegexpOptions: {"strict":true},
    name: "rent-out___de"
  }, {
    path: "/dk/_elements",
    component: _b735053e,
    pathToRegexpOptions: {"strict":true},
    name: "elements___dk"
  }, {
    path: "/dk/account",
    component: _83dfe29a,
    pathToRegexpOptions: {"strict":true},
    name: "account___dk"
  }, {
    path: "/dk/booking-history",
    component: _9aacfe96,
    pathToRegexpOptions: {"strict":true},
    name: "bookings-booking-history___dk"
  }, {
    path: "/dk/bookings",
    component: _725241a7,
    pathToRegexpOptions: {"strict":true},
    name: "bookings___dk"
  }, {
    path: "/dk/brugsbetingelser",
    component: _52e40422,
    pathToRegexpOptions: {"strict":true},
    name: "user-terms___dk"
  }, {
    path: "/dk/company-details",
    component: _4ee290f8,
    pathToRegexpOptions: {"strict":true},
    name: "company-details___dk"
  }, {
    path: "/dk/cookies-dk",
    component: _24c6c615,
    pathToRegexpOptions: {"strict":true},
    name: "cookies___dk"
  }, {
    path: "/dk/economy",
    component: _5e231a44,
    pathToRegexpOptions: {"strict":true},
    name: "economy___dk",
    children: [{
      path: "economy-settings",
      component: _82585e26,
      pathToRegexpOptions: {"strict":true},
      name: "economy-settings___dk"
    }, {
      path: "payout-reports",
      component: _0784f073,
      pathToRegexpOptions: {"strict":true},
      name: "payout-reports___dk"
    }, {
      path: "payouts",
      component: _6847fe76,
      pathToRegexpOptions: {"strict":true},
      name: "payouts___dk"
    }, {
      path: "payouts/:payoutId",
      component: _fb8b5b9a,
      pathToRegexpOptions: {"strict":true},
      name: "payout___dk"
    }]
  }, {
    path: "/dk/edit-vehicles",
    component: _46adcab0,
    pathToRegexpOptions: {"strict":true},
    name: "edit-vehicles___dk"
  }, {
    path: "/dk/favorites",
    component: _8c6d3346,
    pathToRegexpOptions: {"strict":true},
    name: "favorites___dk"
  }, {
    path: "/dk/forsikring",
    component: _6804a7e3,
    pathToRegexpOptions: {"strict":true},
    name: "insurance___dk"
  }, {
    path: "/dk/gavekort",
    component: _5543de20,
    pathToRegexpOptions: {"strict":true},
    name: "gift-cards___dk"
  }, {
    path: "/dk/getting-started",
    component: _77c48004,
    pathToRegexpOptions: {"strict":true},
    name: "getting-started___dk"
  }, {
    path: "/dk/komplementer-profil-udlejer",
    component: _78030dd9,
    pathToRegexpOptions: {"strict":true},
    name: "owner-complete-profile___dk"
  }, {
    path: "/dk/login",
    component: _10ba8d22,
    pathToRegexpOptions: {"strict":true},
    name: "login___dk"
  }, {
    path: "/dk/login-as-user",
    component: _4d9321a8,
    pathToRegexpOptions: {"strict":true},
    name: "login-as-user___dk"
  }, {
    path: "/dk/magasin",
    component: _54b0c181,
    meta: {"hideGettingStartedProgressBar":true},
    pathToRegexpOptions: {"strict":true},
    name: "magazine___dk"
  }, {
    path: "/dk/oauth-callback",
    component: _40fef8b6,
    pathToRegexpOptions: {"strict":true},
    name: "oauth-callback___dk"
  }, {
    path: "/dk/om-dig",
    component: _7b3ab9a5,
    pathToRegexpOptions: {"strict":true},
    name: "renter-about-me___dk"
  }, {
    path: "/dk/om-os",
    component: _0e433b93,
    pathToRegexpOptions: {"strict":true},
    name: "about___dk"
  }, {
    path: "/dk/opret-leje-profil",
    component: _21a10825,
    pathToRegexpOptions: {"strict":true},
    name: "renter-signup___dk"
  }, {
    path: "/dk/our-partners",
    component: _d9c1a60e,
    pathToRegexpOptions: {"strict":true},
    name: "our-partners___dk"
  }, {
    path: "/dk/owner-verify-id",
    component: _638662db,
    pathToRegexpOptions: {"strict":true},
    name: "owner-verify-id___dk"
  }, {
    path: "/dk/privatlivspolitik",
    component: _dae86298,
    pathToRegexpOptions: {"strict":true},
    name: "privacy-policy___dk"
  }, {
    path: "/dk/profile",
    component: _d5f21fa2,
    pathToRegexpOptions: {"strict":true},
    name: "profile___dk"
  }, {
    path: "/dk/restips",
    component: _5e1072de,
    pathToRegexpOptions: {"strict":true},
    name: "restips___dk"
  }, {
    path: "/dk/reviews",
    component: _46ec5cbe,
    pathToRegexpOptions: {"strict":true},
    name: "reviews___dk"
  }, {
    path: "/dk/sign-up",
    component: _0406ba31,
    pathToRegexpOptions: {"strict":true},
    name: "sign-up___dk"
  }, {
    path: "/dk/sitemap",
    component: _07f8a58a,
    pathToRegexpOptions: {"strict":true},
    name: "sitemap___dk"
  }, {
    path: "/dk/udgiv-koeretoej",
    component: _52f6044c,
    pathToRegexpOptions: {"strict":true},
    name: "owner-publish-vehicle___dk"
  }, {
    path: "/dk/udlej-autocamper-campingvogn",
    component: _18055da4,
    meta: {"hideGettingStartedProgressBar":true},
    pathToRegexpOptions: {"strict":true},
    name: "rent-out___dk"
  }, {
    path: "/dk/verificer-id",
    component: _6a65d172,
    pathToRegexpOptions: {"strict":true},
    name: "renter-verify-id___dk"
  }, {
    path: "/fi-sv/_elements",
    component: _b735053e,
    pathToRegexpOptions: {"strict":true},
    name: "elements___fi-sv"
  }, {
    path: "/fi-sv/account",
    component: _83dfe29a,
    pathToRegexpOptions: {"strict":true},
    name: "account___fi-sv"
  }, {
    path: "/fi-sv/anvandarvillkor",
    component: _52e40422,
    pathToRegexpOptions: {"strict":true},
    name: "user-terms___fi-sv"
  }, {
    path: "/fi-sv/booking-history",
    component: _9aacfe96,
    pathToRegexpOptions: {"strict":true},
    name: "bookings-booking-history___fi-sv"
  }, {
    path: "/fi-sv/bookings",
    component: _725241a7,
    pathToRegexpOptions: {"strict":true},
    name: "bookings___fi-sv"
  }, {
    path: "/fi-sv/company-details",
    component: _4ee290f8,
    pathToRegexpOptions: {"strict":true},
    name: "company-details___fi-sv"
  }, {
    path: "/fi-sv/cookies",
    component: _24c6c615,
    pathToRegexpOptions: {"strict":true},
    name: "cookies___fi-sv"
  }, {
    path: "/fi-sv/economy",
    component: _5e231a44,
    pathToRegexpOptions: {"strict":true},
    name: "economy___fi-sv",
    children: [{
      path: "economy-settings",
      component: _82585e26,
      pathToRegexpOptions: {"strict":true},
      name: "economy-settings___fi-sv"
    }, {
      path: "payout-reports",
      component: _0784f073,
      pathToRegexpOptions: {"strict":true},
      name: "payout-reports___fi-sv"
    }, {
      path: "payouts",
      component: _6847fe76,
      pathToRegexpOptions: {"strict":true},
      name: "payouts___fi-sv"
    }, {
      path: "payouts/:payoutId",
      component: _fb8b5b9a,
      pathToRegexpOptions: {"strict":true},
      name: "payout___fi-sv"
    }]
  }, {
    path: "/fi-sv/edit-vehicles",
    component: _46adcab0,
    pathToRegexpOptions: {"strict":true},
    name: "edit-vehicles___fi-sv"
  }, {
    path: "/fi-sv/favorites",
    component: _8c6d3346,
    pathToRegexpOptions: {"strict":true},
    name: "favorites___fi-sv"
  }, {
    path: "/fi-sv/forsakring",
    component: _6804a7e3,
    pathToRegexpOptions: {"strict":true},
    name: "insurance___fi-sv"
  }, {
    path: "/fi-sv/getting-started",
    component: _77c48004,
    pathToRegexpOptions: {"strict":true},
    name: "getting-started___fi-sv"
  }, {
    path: "/fi-sv/hyra-ut-husbil-husvagn",
    component: _18055da4,
    meta: {"hideGettingStartedProgressBar":true},
    pathToRegexpOptions: {"strict":true},
    name: "rent-out___fi-sv"
  }, {
    path: "/fi-sv/info-om-dig",
    component: _7b3ab9a5,
    pathToRegexpOptions: {"strict":true},
    name: "renter-about-me___fi-sv"
  }, {
    path: "/fi-sv/komplettera-profil-agare",
    component: _78030dd9,
    pathToRegexpOptions: {"strict":true},
    name: "owner-complete-profile___fi-sv"
  }, {
    path: "/fi-sv/login",
    component: _10ba8d22,
    pathToRegexpOptions: {"strict":true},
    name: "login___fi-sv"
  }, {
    path: "/fi-sv/login-as-user",
    component: _4d9321a8,
    pathToRegexpOptions: {"strict":true},
    name: "login-as-user___fi-sv"
  }, {
    path: "/fi-sv/magasin",
    component: _54b0c181,
    meta: {"hideGettingStartedProgressBar":true},
    pathToRegexpOptions: {"strict":true},
    name: "magazine___fi-sv"
  }, {
    path: "/fi-sv/oauth-callback",
    component: _40fef8b6,
    pathToRegexpOptions: {"strict":true},
    name: "oauth-callback___fi-sv"
  }, {
    path: "/fi-sv/om-oss",
    component: _0e433b93,
    pathToRegexpOptions: {"strict":true},
    name: "about___fi-sv"
  }, {
    path: "/fi-sv/our-partners",
    component: _d9c1a60e,
    pathToRegexpOptions: {"strict":true},
    name: "our-partners___fi-sv"
  }, {
    path: "/fi-sv/owner-verify-id",
    component: _638662db,
    pathToRegexpOptions: {"strict":true},
    name: "owner-verify-id___fi-sv"
  }, {
    path: "/fi-sv/personuppgifter",
    component: _dae86298,
    pathToRegexpOptions: {"strict":true},
    name: "privacy-policy___fi-sv"
  }, {
    path: "/fi-sv/presentkort",
    component: _5543de20,
    pathToRegexpOptions: {"strict":true},
    name: "gift-cards___fi-sv"
  }, {
    path: "/fi-sv/profile",
    component: _d5f21fa2,
    pathToRegexpOptions: {"strict":true},
    name: "profile___fi-sv"
  }, {
    path: "/fi-sv/publicera-fordon",
    component: _52f6044c,
    pathToRegexpOptions: {"strict":true},
    name: "owner-publish-vehicle___fi-sv"
  }, {
    path: "/fi-sv/restips",
    component: _5e1072de,
    pathToRegexpOptions: {"strict":true},
    name: "restips___fi-sv"
  }, {
    path: "/fi-sv/reviews",
    component: _46ec5cbe,
    pathToRegexpOptions: {"strict":true},
    name: "reviews___fi-sv"
  }, {
    path: "/fi-sv/sign-up",
    component: _0406ba31,
    pathToRegexpOptions: {"strict":true},
    name: "sign-up___fi-sv"
  }, {
    path: "/fi-sv/sitemap",
    component: _07f8a58a,
    pathToRegexpOptions: {"strict":true},
    name: "sitemap___fi-sv"
  }, {
    path: "/fi-sv/skapa-konto-hyrare",
    component: _21a10825,
    pathToRegexpOptions: {"strict":true},
    name: "renter-signup___fi-sv"
  }, {
    path: "/fi-sv/verifiera-id",
    component: _6a65d172,
    pathToRegexpOptions: {"strict":true},
    name: "renter-verify-id___fi-sv"
  }, {
    path: "/fi/_elements",
    component: _b735053e,
    pathToRegexpOptions: {"strict":true},
    name: "elements___fi"
  }, {
    path: "/fi/account",
    component: _83dfe29a,
    pathToRegexpOptions: {"strict":true},
    name: "account___fi"
  }, {
    path: "/fi/anna-vuokralle-matkailuauto-matkailuvaunu",
    component: _18055da4,
    meta: {"hideGettingStartedProgressBar":true},
    pathToRegexpOptions: {"strict":true},
    name: "rent-out___fi"
  }, {
    path: "/fi/booking-history",
    component: _9aacfe96,
    pathToRegexpOptions: {"strict":true},
    name: "bookings-booking-history___fi"
  }, {
    path: "/fi/bookings",
    component: _725241a7,
    pathToRegexpOptions: {"strict":true},
    name: "bookings___fi"
  }, {
    path: "/fi/company-details",
    component: _4ee290f8,
    pathToRegexpOptions: {"strict":true},
    name: "company-details___fi"
  }, {
    path: "/fi/economy",
    component: _5e231a44,
    pathToRegexpOptions: {"strict":true},
    name: "economy___fi",
    children: [{
      path: "economy-settings",
      component: _82585e26,
      pathToRegexpOptions: {"strict":true},
      name: "economy-settings___fi"
    }, {
      path: "payout-reports",
      component: _0784f073,
      pathToRegexpOptions: {"strict":true},
      name: "payout-reports___fi"
    }, {
      path: "payouts",
      component: _6847fe76,
      pathToRegexpOptions: {"strict":true},
      name: "payouts___fi"
    }, {
      path: "payouts/:payoutId",
      component: _fb8b5b9a,
      pathToRegexpOptions: {"strict":true},
      name: "payout___fi"
    }]
  }, {
    path: "/fi/edit-vehicles",
    component: _46adcab0,
    pathToRegexpOptions: {"strict":true},
    name: "edit-vehicles___fi"
  }, {
    path: "/fi/evasteet",
    component: _24c6c615,
    pathToRegexpOptions: {"strict":true},
    name: "cookies___fi"
  }, {
    path: "/fi/favorites",
    component: _8c6d3346,
    pathToRegexpOptions: {"strict":true},
    name: "favorites___fi"
  }, {
    path: "/fi/getting-started",
    component: _77c48004,
    pathToRegexpOptions: {"strict":true},
    name: "getting-started___fi"
  }, {
    path: "/fi/henkilotiedot",
    component: _dae86298,
    pathToRegexpOptions: {"strict":true},
    name: "privacy-policy___fi"
  }, {
    path: "/fi/julkaise-ajoneuvo",
    component: _52f6044c,
    pathToRegexpOptions: {"strict":true},
    name: "owner-publish-vehicle___fi"
  }, {
    path: "/fi/kayttoehdot",
    component: _52e40422,
    pathToRegexpOptions: {"strict":true},
    name: "user-terms___fi"
  }, {
    path: "/fi/lahjakortti",
    component: _5543de20,
    pathToRegexpOptions: {"strict":true},
    name: "gift-cards___fi"
  }, {
    path: "/fi/lehti",
    component: _54b0c181,
    meta: {"hideGettingStartedProgressBar":true},
    pathToRegexpOptions: {"strict":true},
    name: "magazine___fi"
  }, {
    path: "/fi/login",
    component: _10ba8d22,
    pathToRegexpOptions: {"strict":true},
    name: "login___fi"
  }, {
    path: "/fi/login-as-user",
    component: _4d9321a8,
    pathToRegexpOptions: {"strict":true},
    name: "login-as-user___fi"
  }, {
    path: "/fi/luo-kayttajatili-vuokraaja",
    component: _21a10825,
    pathToRegexpOptions: {"strict":true},
    name: "renter-signup___fi"
  }, {
    path: "/fi/oauth-callback",
    component: _40fef8b6,
    pathToRegexpOptions: {"strict":true},
    name: "oauth-callback___fi"
  }, {
    path: "/fi/our-partners",
    component: _d9c1a60e,
    pathToRegexpOptions: {"strict":true},
    name: "our-partners___fi"
  }, {
    path: "/fi/owner-verify-id",
    component: _638662db,
    pathToRegexpOptions: {"strict":true},
    name: "owner-verify-id___fi"
  }, {
    path: "/fi/profile",
    component: _d5f21fa2,
    pathToRegexpOptions: {"strict":true},
    name: "profile___fi"
  }, {
    path: "/fi/restips",
    component: _5e1072de,
    pathToRegexpOptions: {"strict":true},
    name: "restips___fi"
  }, {
    path: "/fi/reviews",
    component: _46ec5cbe,
    pathToRegexpOptions: {"strict":true},
    name: "reviews___fi"
  }, {
    path: "/fi/sign-up",
    component: _0406ba31,
    pathToRegexpOptions: {"strict":true},
    name: "sign-up___fi"
  }, {
    path: "/fi/sivukartta",
    component: _07f8a58a,
    pathToRegexpOptions: {"strict":true},
    name: "sitemap___fi"
  }, {
    path: "/fi/taeydennae-profiili-omistaja",
    component: _78030dd9,
    pathToRegexpOptions: {"strict":true},
    name: "owner-complete-profile___fi"
  }, {
    path: "/fi/tietoa-meista",
    component: _0e433b93,
    pathToRegexpOptions: {"strict":true},
    name: "about___fi"
  }, {
    path: "/fi/tietoa-sinusta",
    component: _7b3ab9a5,
    pathToRegexpOptions: {"strict":true},
    name: "renter-about-me___fi"
  }, {
    path: "/fi/vakuutus",
    component: _6804a7e3,
    pathToRegexpOptions: {"strict":true},
    name: "insurance___fi"
  }, {
    path: "/fi/varmenna-henkilollisyys",
    component: _6a65d172,
    pathToRegexpOptions: {"strict":true},
    name: "renter-verify-id___fi"
  }, {
    path: "/insurance/baloise",
    component: _0b9f348a,
    pathToRegexpOptions: {"strict":true},
    name: "insurance-baloise___en"
  }, {
    path: "/insurance/omocom",
    component: _823f0c62,
    pathToRegexpOptions: {"strict":true},
    name: "insurance-omocom___en"
  }, {
    path: "/insurance/owners-insurance",
    component: _2b8dfe6c,
    pathToRegexpOptions: {"strict":true},
    name: "insurance-owners-insurance___en"
  }, {
    path: "/no/_elements",
    component: _b735053e,
    pathToRegexpOptions: {"strict":true},
    name: "elements___no"
  }, {
    path: "/no/account",
    component: _83dfe29a,
    pathToRegexpOptions: {"strict":true},
    name: "account___no"
  }, {
    path: "/no/booking-history",
    component: _9aacfe96,
    pathToRegexpOptions: {"strict":true},
    name: "bookings-booking-history___no"
  }, {
    path: "/no/bookings",
    component: _725241a7,
    pathToRegexpOptions: {"strict":true},
    name: "bookings___no"
  }, {
    path: "/no/brukervilkar",
    component: _52e40422,
    pathToRegexpOptions: {"strict":true},
    name: "user-terms___no"
  }, {
    path: "/no/company-details",
    component: _4ee290f8,
    pathToRegexpOptions: {"strict":true},
    name: "company-details___no"
  }, {
    path: "/no/cookies",
    component: _24c6c615,
    pathToRegexpOptions: {"strict":true},
    name: "cookies___no"
  }, {
    path: "/no/economy",
    component: _5e231a44,
    pathToRegexpOptions: {"strict":true},
    name: "economy___no",
    children: [{
      path: "economy-settings",
      component: _82585e26,
      pathToRegexpOptions: {"strict":true},
      name: "economy-settings___no"
    }, {
      path: "payout-reports",
      component: _0784f073,
      pathToRegexpOptions: {"strict":true},
      name: "payout-reports___no"
    }, {
      path: "payouts",
      component: _6847fe76,
      pathToRegexpOptions: {"strict":true},
      name: "payouts___no"
    }, {
      path: "payouts/:payoutId",
      component: _fb8b5b9a,
      pathToRegexpOptions: {"strict":true},
      name: "payout___no"
    }]
  }, {
    path: "/no/edit-vehicles",
    component: _46adcab0,
    pathToRegexpOptions: {"strict":true},
    name: "edit-vehicles___no"
  }, {
    path: "/no/favorites",
    component: _8c6d3346,
    pathToRegexpOptions: {"strict":true},
    name: "favorites___no"
  }, {
    path: "/no/forsikring",
    component: _6804a7e3,
    pathToRegexpOptions: {"strict":true},
    name: "insurance___no"
  }, {
    path: "/no/fullfoer-profilen-eier",
    component: _78030dd9,
    pathToRegexpOptions: {"strict":true},
    name: "owner-complete-profile___no"
  }, {
    path: "/no/gavekort",
    component: _5543de20,
    pathToRegexpOptions: {"strict":true},
    name: "gift-cards___no"
  }, {
    path: "/no/getting-started",
    component: _77c48004,
    pathToRegexpOptions: {"strict":true},
    name: "getting-started___no"
  }, {
    path: "/no/info-om-deg",
    component: _7b3ab9a5,
    pathToRegexpOptions: {"strict":true},
    name: "renter-about-me___no"
  }, {
    path: "/no/lei-ut-bobil-campingvogn",
    component: _18055da4,
    meta: {"hideGettingStartedProgressBar":true},
    pathToRegexpOptions: {"strict":true},
    name: "rent-out___no"
  }, {
    path: "/no/login",
    component: _10ba8d22,
    pathToRegexpOptions: {"strict":true},
    name: "login___no"
  }, {
    path: "/no/login-as-user",
    component: _4d9321a8,
    pathToRegexpOptions: {"strict":true},
    name: "login-as-user___no"
  }, {
    path: "/no/magasin",
    component: _54b0c181,
    meta: {"hideGettingStartedProgressBar":true},
    pathToRegexpOptions: {"strict":true},
    name: "magazine___no"
  }, {
    path: "/no/oauth-callback",
    component: _40fef8b6,
    pathToRegexpOptions: {"strict":true},
    name: "oauth-callback___no"
  }, {
    path: "/no/om-oss",
    component: _0e433b93,
    pathToRegexpOptions: {"strict":true},
    name: "about___no"
  }, {
    path: "/no/opprette-konto-leietaker",
    component: _21a10825,
    pathToRegexpOptions: {"strict":true},
    name: "renter-signup___no"
  }, {
    path: "/no/our-partners",
    component: _d9c1a60e,
    pathToRegexpOptions: {"strict":true},
    name: "our-partners___no"
  }, {
    path: "/no/owner-verify-id",
    component: _638662db,
    pathToRegexpOptions: {"strict":true},
    name: "owner-verify-id___no"
  }, {
    path: "/no/personopplysninger",
    component: _dae86298,
    pathToRegexpOptions: {"strict":true},
    name: "privacy-policy___no"
  }, {
    path: "/no/profile",
    component: _d5f21fa2,
    pathToRegexpOptions: {"strict":true},
    name: "profile___no"
  }, {
    path: "/no/publisere-kjoretoy",
    component: _52f6044c,
    pathToRegexpOptions: {"strict":true},
    name: "owner-publish-vehicle___no"
  }, {
    path: "/no/restips",
    component: _5e1072de,
    pathToRegexpOptions: {"strict":true},
    name: "restips___no"
  }, {
    path: "/no/reviews",
    component: _46ec5cbe,
    pathToRegexpOptions: {"strict":true},
    name: "reviews___no"
  }, {
    path: "/no/sign-up",
    component: _0406ba31,
    pathToRegexpOptions: {"strict":true},
    name: "sign-up___no"
  }, {
    path: "/no/sitemap",
    component: _07f8a58a,
    pathToRegexpOptions: {"strict":true},
    name: "sitemap___no"
  }, {
    path: "/no/verifiser-med-bankid",
    component: _6a65d172,
    pathToRegexpOptions: {"strict":true},
    name: "renter-verify-id___no"
  }, {
    path: "/our-partners/amag",
    component: _e8a7430a,
    pathToRegexpOptions: {"strict":true},
    name: "our-partners-amag___en"
  }, {
    path: "/our-partners/caravan4rep",
    component: _8064d774,
    pathToRegexpOptions: {"strict":true},
    name: "our-partners-caravan4rep___en"
  }, {
    path: "/our-partners/ferrycenter",
    component: _3b68d856,
    pathToRegexpOptions: {"strict":true},
    name: "our-partners-ferrycenter___en"
  }, {
    path: "/our-partners/myclimate",
    component: _15102a20,
    pathToRegexpOptions: {"strict":true},
    name: "our-partners-myclimate___en"
  }, {
    path: "/owner/dashboard",
    component: _5222573e,
    pathToRegexpOptions: {"strict":true},
    name: "owner-dashboard___en"
  }, {
    path: "/owner/top-owner",
    component: _098e1536,
    pathToRegexpOptions: {"strict":true},
    name: "owner-top-owner___en"
  }, {
    path: "/se/_elements",
    component: _b735053e,
    pathToRegexpOptions: {"strict":true},
    name: "elements___se"
  }, {
    path: "/se/account",
    component: _83dfe29a,
    pathToRegexpOptions: {"strict":true},
    name: "account___se"
  }, {
    path: "/se/anvandarvillkor",
    component: _52e40422,
    pathToRegexpOptions: {"strict":true},
    name: "user-terms___se"
  }, {
    path: "/se/booking-history",
    component: _9aacfe96,
    pathToRegexpOptions: {"strict":true},
    name: "bookings-booking-history___se"
  }, {
    path: "/se/bookings",
    component: _725241a7,
    pathToRegexpOptions: {"strict":true},
    name: "bookings___se"
  }, {
    path: "/se/company-details",
    component: _4ee290f8,
    pathToRegexpOptions: {"strict":true},
    name: "company-details___se"
  }, {
    path: "/se/cookies",
    component: _24c6c615,
    pathToRegexpOptions: {"strict":true},
    name: "cookies___se"
  }, {
    path: "/se/economy",
    component: _5e231a44,
    pathToRegexpOptions: {"strict":true},
    name: "economy___se",
    children: [{
      path: "economy-settings",
      component: _82585e26,
      pathToRegexpOptions: {"strict":true},
      name: "economy-settings___se"
    }, {
      path: "payout-reports",
      component: _0784f073,
      pathToRegexpOptions: {"strict":true},
      name: "payout-reports___se"
    }, {
      path: "payouts",
      component: _6847fe76,
      pathToRegexpOptions: {"strict":true},
      name: "payouts___se"
    }, {
      path: "payouts/:payoutId",
      component: _fb8b5b9a,
      pathToRegexpOptions: {"strict":true},
      name: "payout___se"
    }]
  }, {
    path: "/se/edit-vehicles",
    component: _46adcab0,
    pathToRegexpOptions: {"strict":true},
    name: "edit-vehicles___se"
  }, {
    path: "/se/favorites",
    component: _8c6d3346,
    pathToRegexpOptions: {"strict":true},
    name: "favorites___se"
  }, {
    path: "/se/forsakring",
    component: _6804a7e3,
    pathToRegexpOptions: {"strict":true},
    name: "insurance___se"
  }, {
    path: "/se/getting-started",
    component: _77c48004,
    pathToRegexpOptions: {"strict":true},
    name: "getting-started___se"
  }, {
    path: "/se/hyra-ut-husbil-husvagn",
    component: _18055da4,
    meta: {"hideGettingStartedProgressBar":true},
    pathToRegexpOptions: {"strict":true},
    name: "rent-out___se"
  }, {
    path: "/se/info-om-dig",
    component: _7b3ab9a5,
    pathToRegexpOptions: {"strict":true},
    name: "renter-about-me___se"
  }, {
    path: "/se/komplettera-profil-agare",
    component: _78030dd9,
    pathToRegexpOptions: {"strict":true},
    name: "owner-complete-profile___se"
  }, {
    path: "/se/login",
    component: _10ba8d22,
    pathToRegexpOptions: {"strict":true},
    name: "login___se"
  }, {
    path: "/se/login-as-user",
    component: _4d9321a8,
    pathToRegexpOptions: {"strict":true},
    name: "login-as-user___se"
  }, {
    path: "/se/magasin",
    component: _54b0c181,
    meta: {"hideGettingStartedProgressBar":true},
    pathToRegexpOptions: {"strict":true},
    name: "magazine___se"
  }, {
    path: "/se/oauth-callback",
    component: _40fef8b6,
    pathToRegexpOptions: {"strict":true},
    name: "oauth-callback___se"
  }, {
    path: "/se/om-oss",
    component: _0e433b93,
    pathToRegexpOptions: {"strict":true},
    name: "about___se"
  }, {
    path: "/se/our-partners",
    component: _d9c1a60e,
    pathToRegexpOptions: {"strict":true},
    name: "our-partners___se"
  }, {
    path: "/se/owner-verify-id",
    component: _638662db,
    pathToRegexpOptions: {"strict":true},
    name: "owner-verify-id___se"
  }, {
    path: "/se/personuppgifter",
    component: _dae86298,
    pathToRegexpOptions: {"strict":true},
    name: "privacy-policy___se"
  }, {
    path: "/se/presentkort",
    component: _5543de20,
    pathToRegexpOptions: {"strict":true},
    name: "gift-cards___se"
  }, {
    path: "/se/profile",
    component: _d5f21fa2,
    pathToRegexpOptions: {"strict":true},
    name: "profile___se"
  }, {
    path: "/se/publicera-fordon",
    component: _52f6044c,
    pathToRegexpOptions: {"strict":true},
    name: "owner-publish-vehicle___se"
  }, {
    path: "/se/restips",
    component: _5e1072de,
    pathToRegexpOptions: {"strict":true},
    name: "restips___se"
  }, {
    path: "/se/reviews",
    component: _46ec5cbe,
    pathToRegexpOptions: {"strict":true},
    name: "reviews___se"
  }, {
    path: "/se/sign-up",
    component: _0406ba31,
    pathToRegexpOptions: {"strict":true},
    name: "sign-up___se"
  }, {
    path: "/se/sitemap",
    component: _07f8a58a,
    pathToRegexpOptions: {"strict":true},
    name: "sitemap___se"
  }, {
    path: "/se/skapa-konto-hyrare",
    component: _21a10825,
    pathToRegexpOptions: {"strict":true},
    name: "renter-signup___se"
  }, {
    path: "/se/verifiera-id",
    component: _6a65d172,
    pathToRegexpOptions: {"strict":true},
    name: "renter-verify-id___se"
  }, {
    path: "/de/our-partners/amag",
    component: _e8a7430a,
    pathToRegexpOptions: {"strict":true},
    name: "our-partners-amag___de"
  }, {
    path: "/de/our-partners/caravan4rep",
    component: _8064d774,
    pathToRegexpOptions: {"strict":true},
    name: "our-partners-caravan4rep___de"
  }, {
    path: "/de/our-partners/ferrycenter",
    component: _3b68d856,
    pathToRegexpOptions: {"strict":true},
    name: "our-partners-ferrycenter___de"
  }, {
    path: "/de/our-partners/myclimate",
    component: _15102a20,
    pathToRegexpOptions: {"strict":true},
    name: "our-partners-myclimate___de"
  }, {
    path: "/de/owner/dashboard",
    component: _5222573e,
    pathToRegexpOptions: {"strict":true},
    name: "owner-dashboard___de"
  }, {
    path: "/de/owner/top-owner",
    component: _098e1536,
    pathToRegexpOptions: {"strict":true},
    name: "owner-top-owner___de"
  }, {
    path: "/de/versicherung/baloise",
    component: _0b9f348a,
    pathToRegexpOptions: {"strict":true},
    name: "insurance-baloise___de"
  }, {
    path: "/de/versicherung/omocom",
    component: _823f0c62,
    pathToRegexpOptions: {"strict":true},
    name: "insurance-omocom___de"
  }, {
    path: "/de/versicherung/versicherung-des-vermietenden",
    component: _2b8dfe6c,
    pathToRegexpOptions: {"strict":true},
    name: "insurance-owners-insurance___de"
  }, {
    path: "/dk/forsikring/baloise",
    component: _0b9f348a,
    pathToRegexpOptions: {"strict":true},
    name: "insurance-baloise___dk"
  }, {
    path: "/dk/forsikring/ejerens-forsikring",
    component: _2b8dfe6c,
    pathToRegexpOptions: {"strict":true},
    name: "insurance-owners-insurance___dk"
  }, {
    path: "/dk/forsikring/omocom",
    component: _823f0c62,
    pathToRegexpOptions: {"strict":true},
    name: "insurance-omocom___dk"
  }, {
    path: "/dk/our-partners/amag",
    component: _e8a7430a,
    pathToRegexpOptions: {"strict":true},
    name: "our-partners-amag___dk"
  }, {
    path: "/dk/our-partners/caravan4rep",
    component: _8064d774,
    pathToRegexpOptions: {"strict":true},
    name: "our-partners-caravan4rep___dk"
  }, {
    path: "/dk/our-partners/ferrycenter",
    component: _3b68d856,
    pathToRegexpOptions: {"strict":true},
    name: "our-partners-ferrycenter___dk"
  }, {
    path: "/dk/our-partners/myclimate",
    component: _15102a20,
    pathToRegexpOptions: {"strict":true},
    name: "our-partners-myclimate___dk"
  }, {
    path: "/dk/owner/dashboard",
    component: _5222573e,
    pathToRegexpOptions: {"strict":true},
    name: "owner-dashboard___dk"
  }, {
    path: "/dk/owner/top-owner",
    component: _098e1536,
    pathToRegexpOptions: {"strict":true},
    name: "owner-top-owner___dk"
  }, {
    path: "/fi-sv/forsakring/agarens-forsakring",
    component: _2b8dfe6c,
    pathToRegexpOptions: {"strict":true},
    name: "insurance-owners-insurance___fi-sv"
  }, {
    path: "/fi-sv/forsakring/baloise",
    component: _0b9f348a,
    pathToRegexpOptions: {"strict":true},
    name: "insurance-baloise___fi-sv"
  }, {
    path: "/fi-sv/forsakring/omocom",
    component: _823f0c62,
    pathToRegexpOptions: {"strict":true},
    name: "insurance-omocom___fi-sv"
  }, {
    path: "/fi-sv/our-partners/amag",
    component: _e8a7430a,
    pathToRegexpOptions: {"strict":true},
    name: "our-partners-amag___fi-sv"
  }, {
    path: "/fi-sv/our-partners/caravan4rep",
    component: _8064d774,
    pathToRegexpOptions: {"strict":true},
    name: "our-partners-caravan4rep___fi-sv"
  }, {
    path: "/fi-sv/our-partners/ferrycenter",
    component: _3b68d856,
    pathToRegexpOptions: {"strict":true},
    name: "our-partners-ferrycenter___fi-sv"
  }, {
    path: "/fi-sv/our-partners/myclimate",
    component: _15102a20,
    pathToRegexpOptions: {"strict":true},
    name: "our-partners-myclimate___fi-sv"
  }, {
    path: "/fi-sv/owner/dashboard",
    component: _5222573e,
    pathToRegexpOptions: {"strict":true},
    name: "owner-dashboard___fi-sv"
  }, {
    path: "/fi-sv/owner/top-owner",
    component: _098e1536,
    pathToRegexpOptions: {"strict":true},
    name: "owner-top-owner___fi-sv"
  }, {
    path: "/fi/our-partners/amag",
    component: _e8a7430a,
    pathToRegexpOptions: {"strict":true},
    name: "our-partners-amag___fi"
  }, {
    path: "/fi/our-partners/caravan4rep",
    component: _8064d774,
    pathToRegexpOptions: {"strict":true},
    name: "our-partners-caravan4rep___fi"
  }, {
    path: "/fi/our-partners/ferrycenter",
    component: _3b68d856,
    pathToRegexpOptions: {"strict":true},
    name: "our-partners-ferrycenter___fi"
  }, {
    path: "/fi/our-partners/myclimate",
    component: _15102a20,
    pathToRegexpOptions: {"strict":true},
    name: "our-partners-myclimate___fi"
  }, {
    path: "/fi/owner/dashboard",
    component: _5222573e,
    pathToRegexpOptions: {"strict":true},
    name: "owner-dashboard___fi"
  }, {
    path: "/fi/owner/top-owner",
    component: _098e1536,
    pathToRegexpOptions: {"strict":true},
    name: "owner-top-owner___fi"
  }, {
    path: "/fi/vakuutus/baloise",
    component: _0b9f348a,
    pathToRegexpOptions: {"strict":true},
    name: "insurance-baloise___fi"
  }, {
    path: "/fi/vakuutus/omistajan-vakuutus",
    component: _2b8dfe6c,
    pathToRegexpOptions: {"strict":true},
    name: "insurance-owners-insurance___fi"
  }, {
    path: "/fi/vakuutus/omocom",
    component: _823f0c62,
    pathToRegexpOptions: {"strict":true},
    name: "insurance-omocom___fi"
  }, {
    path: "/no/forsikring/baloise",
    component: _0b9f348a,
    pathToRegexpOptions: {"strict":true},
    name: "insurance-baloise___no"
  }, {
    path: "/no/forsikring/eierens-forsikring",
    component: _2b8dfe6c,
    pathToRegexpOptions: {"strict":true},
    name: "insurance-owners-insurance___no"
  }, {
    path: "/no/forsikring/omocom",
    component: _823f0c62,
    pathToRegexpOptions: {"strict":true},
    name: "insurance-omocom___no"
  }, {
    path: "/no/our-partners/amag",
    component: _e8a7430a,
    pathToRegexpOptions: {"strict":true},
    name: "our-partners-amag___no"
  }, {
    path: "/no/our-partners/caravan4rep",
    component: _8064d774,
    pathToRegexpOptions: {"strict":true},
    name: "our-partners-caravan4rep___no"
  }, {
    path: "/no/our-partners/ferrycenter",
    component: _3b68d856,
    pathToRegexpOptions: {"strict":true},
    name: "our-partners-ferrycenter___no"
  }, {
    path: "/no/our-partners/myclimate",
    component: _15102a20,
    pathToRegexpOptions: {"strict":true},
    name: "our-partners-myclimate___no"
  }, {
    path: "/no/owner/dashboard",
    component: _5222573e,
    pathToRegexpOptions: {"strict":true},
    name: "owner-dashboard___no"
  }, {
    path: "/no/owner/top-owner",
    component: _098e1536,
    pathToRegexpOptions: {"strict":true},
    name: "owner-top-owner___no"
  }, {
    path: "/se/forsakring/agarens-forsakring",
    component: _2b8dfe6c,
    pathToRegexpOptions: {"strict":true},
    name: "insurance-owners-insurance___se"
  }, {
    path: "/se/forsakring/baloise",
    component: _0b9f348a,
    pathToRegexpOptions: {"strict":true},
    name: "insurance-baloise___se"
  }, {
    path: "/se/forsakring/omocom",
    component: _823f0c62,
    pathToRegexpOptions: {"strict":true},
    name: "insurance-omocom___se"
  }, {
    path: "/se/our-partners/amag",
    component: _e8a7430a,
    pathToRegexpOptions: {"strict":true},
    name: "our-partners-amag___se"
  }, {
    path: "/se/our-partners/caravan4rep",
    component: _8064d774,
    pathToRegexpOptions: {"strict":true},
    name: "our-partners-caravan4rep___se"
  }, {
    path: "/se/our-partners/ferrycenter",
    component: _3b68d856,
    pathToRegexpOptions: {"strict":true},
    name: "our-partners-ferrycenter___se"
  }, {
    path: "/se/our-partners/myclimate",
    component: _15102a20,
    pathToRegexpOptions: {"strict":true},
    name: "our-partners-myclimate___se"
  }, {
    path: "/se/owner/dashboard",
    component: _5222573e,
    pathToRegexpOptions: {"strict":true},
    name: "owner-dashboard___se"
  }, {
    path: "/se/owner/top-owner",
    component: _098e1536,
    pathToRegexpOptions: {"strict":true},
    name: "owner-top-owner___se"
  }, {
    path: "/de/magazin/category/:slug?",
    component: _4ec86fcc,
    meta: {"hideGettingStartedProgressBar":true},
    pathToRegexpOptions: {"strict":true},
    name: "magazine-category-slug___de"
  }, {
    path: "/de/magazin/tag/:slug?",
    component: _aaf5eef8,
    meta: {"hideGettingStartedProgressBar":true},
    pathToRegexpOptions: {"strict":true},
    name: "magazine-tag-slug___de"
  }, {
    path: "/dk/magasin/category/:slug?",
    component: _4ec86fcc,
    meta: {"hideGettingStartedProgressBar":true},
    pathToRegexpOptions: {"strict":true},
    name: "magazine-category-slug___dk"
  }, {
    path: "/dk/magasin/tag/:slug?",
    component: _aaf5eef8,
    meta: {"hideGettingStartedProgressBar":true},
    pathToRegexpOptions: {"strict":true},
    name: "magazine-tag-slug___dk"
  }, {
    path: "/fi-sv/magasin/category/:slug?",
    component: _4ec86fcc,
    meta: {"hideGettingStartedProgressBar":true},
    pathToRegexpOptions: {"strict":true},
    name: "magazine-category-slug___fi-sv"
  }, {
    path: "/fi-sv/magasin/tag/:slug?",
    component: _aaf5eef8,
    meta: {"hideGettingStartedProgressBar":true},
    pathToRegexpOptions: {"strict":true},
    name: "magazine-tag-slug___fi-sv"
  }, {
    path: "/fi/lehti/category/:slug?",
    component: _4ec86fcc,
    meta: {"hideGettingStartedProgressBar":true},
    pathToRegexpOptions: {"strict":true},
    name: "magazine-category-slug___fi"
  }, {
    path: "/fi/lehti/tag/:slug?",
    component: _aaf5eef8,
    meta: {"hideGettingStartedProgressBar":true},
    pathToRegexpOptions: {"strict":true},
    name: "magazine-tag-slug___fi"
  }, {
    path: "/no/magasin/category/:slug?",
    component: _4ec86fcc,
    meta: {"hideGettingStartedProgressBar":true},
    pathToRegexpOptions: {"strict":true},
    name: "magazine-category-slug___no"
  }, {
    path: "/no/magasin/tag/:slug?",
    component: _aaf5eef8,
    meta: {"hideGettingStartedProgressBar":true},
    pathToRegexpOptions: {"strict":true},
    name: "magazine-tag-slug___no"
  }, {
    path: "/se/magasin/category/:slug?",
    component: _4ec86fcc,
    meta: {"hideGettingStartedProgressBar":true},
    pathToRegexpOptions: {"strict":true},
    name: "magazine-category-slug___se"
  }, {
    path: "/se/magasin/tag/:slug?",
    component: _aaf5eef8,
    meta: {"hideGettingStartedProgressBar":true},
    pathToRegexpOptions: {"strict":true},
    name: "magazine-tag-slug___se"
  }, {
    path: "/de/bookings/:id",
    component: _0d543112,
    pathToRegexpOptions: {"strict":true},
    name: "booking___de"
  }, {
    path: "/de/edit-vehicle/:id?",
    component: _6b619cfe,
    pathToRegexpOptions: {"strict":true},
    name: "edit-vehicle-id___de",
    children: [{
      path: "activation",
      component: _237df477,
      pathToRegexpOptions: {"strict":true},
      name: "edit-vehicle-activation___de"
    }, {
      path: "availability",
      component: _3e33e008,
      pathToRegexpOptions: {"strict":true},
      name: "edit-vehicle-availability___de"
    }, {
      path: "basic",
      component: _45d28a5d,
      pathToRegexpOptions: {"strict":true},
      name: "edit-vehicle-basic___de"
    }, {
      path: "cancellation",
      component: _74d46fa4,
      pathToRegexpOptions: {"strict":true},
      name: "edit-vehicle-cancellation___de"
    }, {
      path: "checkin-checkout",
      component: _bd5d98f2,
      pathToRegexpOptions: {"strict":true},
      name: "edit-vehicle-checkin-checkout___de"
    }, {
      path: "description",
      component: _3cea15eb,
      pathToRegexpOptions: {"strict":true},
      name: "edit-vehicle-description___de"
    }, {
      path: "equipment",
      component: _b6ca6f86,
      pathToRegexpOptions: {"strict":true},
      name: "edit-vehicle-equipment___de"
    }, {
      path: "images",
      component: _6529c159,
      pathToRegexpOptions: {"strict":true},
      name: "edit-vehicle-images___de"
    }, {
      path: "insurance",
      component: _b897f8ee,
      pathToRegexpOptions: {"strict":true},
      name: "edit-vehicle-insurance___de"
    }, {
      path: "location",
      component: _35b9ec54,
      pathToRegexpOptions: {"strict":true},
      name: "edit-vehicle-location___de"
    }, {
      path: "pricing",
      component: _2f1eff96,
      pathToRegexpOptions: {"strict":true},
      name: "edit-vehicle-pricing___de"
    }, {
      path: "rules",
      component: _2f7372a6,
      pathToRegexpOptions: {"strict":true},
      name: "edit-vehicle-rules___de"
    }]
  }, {
    path: "/de/fahrzeuge/:id",
    component: _7c9fe4c0,
    pathToRegexpOptions: {"strict":true},
    name: "vehicle___de"
  }, {
    path: "/de/login-as-user-from-admin/:id",
    component: _4e4c09e2,
    pathToRegexpOptions: {"strict":true},
    name: "login-as-user-from-admin-id___de"
  }, {
    path: "/de/magazin/:slug?",
    component: _52fa2039,
    meta: {"hideGettingStartedProgressBar":true},
    pathToRegexpOptions: {"strict":true},
    name: "magazine-slug___de"
  }, {
    path: "/de/messages/:conversationId?",
    component: _202b5344,
    meta: {"hideGettingStartedProgressBar":true},
    pathToRegexpOptions: {"strict":true},
    name: "messages___de"
  }, {
    path: "/de/owner/:id",
    component: _8c845e38,
    pathToRegexpOptions: {"strict":true},
    name: "owner-id___de"
  }, {
    path: "/de/reset-password/:resetCode?",
    component: _56eec2d9,
    pathToRegexpOptions: {"strict":true},
    name: "reset-password-resetCode___de"
  }, {
    path: "/de/verify-email/:inputCode?",
    component: _bb188bf6,
    pathToRegexpOptions: {"strict":true},
    name: "verify-email-inputCode___de"
  }, {
    path: "/dk/bookings/:id",
    component: _0d543112,
    pathToRegexpOptions: {"strict":true},
    name: "booking___dk"
  }, {
    path: "/dk/edit-vehicle/:id?",
    component: _6b619cfe,
    pathToRegexpOptions: {"strict":true},
    name: "edit-vehicle-id___dk",
    children: [{
      path: "activation",
      component: _237df477,
      pathToRegexpOptions: {"strict":true},
      name: "edit-vehicle-activation___dk"
    }, {
      path: "availability",
      component: _3e33e008,
      pathToRegexpOptions: {"strict":true},
      name: "edit-vehicle-availability___dk"
    }, {
      path: "basic",
      component: _45d28a5d,
      pathToRegexpOptions: {"strict":true},
      name: "edit-vehicle-basic___dk"
    }, {
      path: "cancellation",
      component: _74d46fa4,
      pathToRegexpOptions: {"strict":true},
      name: "edit-vehicle-cancellation___dk"
    }, {
      path: "checkin-checkout",
      component: _bd5d98f2,
      pathToRegexpOptions: {"strict":true},
      name: "edit-vehicle-checkin-checkout___dk"
    }, {
      path: "description",
      component: _3cea15eb,
      pathToRegexpOptions: {"strict":true},
      name: "edit-vehicle-description___dk"
    }, {
      path: "equipment",
      component: _b6ca6f86,
      pathToRegexpOptions: {"strict":true},
      name: "edit-vehicle-equipment___dk"
    }, {
      path: "images",
      component: _6529c159,
      pathToRegexpOptions: {"strict":true},
      name: "edit-vehicle-images___dk"
    }, {
      path: "insurance",
      component: _b897f8ee,
      pathToRegexpOptions: {"strict":true},
      name: "edit-vehicle-insurance___dk"
    }, {
      path: "location",
      component: _35b9ec54,
      pathToRegexpOptions: {"strict":true},
      name: "edit-vehicle-location___dk"
    }, {
      path: "pricing",
      component: _2f1eff96,
      pathToRegexpOptions: {"strict":true},
      name: "edit-vehicle-pricing___dk"
    }, {
      path: "rules",
      component: _2f7372a6,
      pathToRegexpOptions: {"strict":true},
      name: "edit-vehicle-rules___dk"
    }]
  }, {
    path: "/dk/koeretoejer/:id",
    component: _7c9fe4c0,
    pathToRegexpOptions: {"strict":true},
    name: "vehicle___dk"
  }, {
    path: "/dk/login-as-user-from-admin/:id",
    component: _4e4c09e2,
    pathToRegexpOptions: {"strict":true},
    name: "login-as-user-from-admin-id___dk"
  }, {
    path: "/dk/magasin/:slug?",
    component: _52fa2039,
    meta: {"hideGettingStartedProgressBar":true},
    pathToRegexpOptions: {"strict":true},
    name: "magazine-slug___dk"
  }, {
    path: "/dk/messages/:conversationId?",
    component: _202b5344,
    meta: {"hideGettingStartedProgressBar":true},
    pathToRegexpOptions: {"strict":true},
    name: "messages___dk"
  }, {
    path: "/dk/reset-password/:resetCode?",
    component: _56eec2d9,
    pathToRegexpOptions: {"strict":true},
    name: "reset-password-resetCode___dk"
  }, {
    path: "/dk/udlejer/:id",
    component: _8c845e38,
    pathToRegexpOptions: {"strict":true},
    name: "owner-id___dk"
  }, {
    path: "/dk/verify-email/:inputCode?",
    component: _bb188bf6,
    pathToRegexpOptions: {"strict":true},
    name: "verify-email-inputCode___dk"
  }, {
    path: "/fi-sv/agare/:id",
    component: _8c845e38,
    pathToRegexpOptions: {"strict":true},
    name: "owner-id___fi-sv"
  }, {
    path: "/fi-sv/bookings/:id",
    component: _0d543112,
    pathToRegexpOptions: {"strict":true},
    name: "booking___fi-sv"
  }, {
    path: "/fi-sv/edit-vehicle/:id?",
    component: _6b619cfe,
    pathToRegexpOptions: {"strict":true},
    name: "edit-vehicle-id___fi-sv",
    children: [{
      path: "activation",
      component: _237df477,
      pathToRegexpOptions: {"strict":true},
      name: "edit-vehicle-activation___fi-sv"
    }, {
      path: "availability",
      component: _3e33e008,
      pathToRegexpOptions: {"strict":true},
      name: "edit-vehicle-availability___fi-sv"
    }, {
      path: "basic",
      component: _45d28a5d,
      pathToRegexpOptions: {"strict":true},
      name: "edit-vehicle-basic___fi-sv"
    }, {
      path: "cancellation",
      component: _74d46fa4,
      pathToRegexpOptions: {"strict":true},
      name: "edit-vehicle-cancellation___fi-sv"
    }, {
      path: "checkin-checkout",
      component: _bd5d98f2,
      pathToRegexpOptions: {"strict":true},
      name: "edit-vehicle-checkin-checkout___fi-sv"
    }, {
      path: "description",
      component: _3cea15eb,
      pathToRegexpOptions: {"strict":true},
      name: "edit-vehicle-description___fi-sv"
    }, {
      path: "equipment",
      component: _b6ca6f86,
      pathToRegexpOptions: {"strict":true},
      name: "edit-vehicle-equipment___fi-sv"
    }, {
      path: "images",
      component: _6529c159,
      pathToRegexpOptions: {"strict":true},
      name: "edit-vehicle-images___fi-sv"
    }, {
      path: "insurance",
      component: _b897f8ee,
      pathToRegexpOptions: {"strict":true},
      name: "edit-vehicle-insurance___fi-sv"
    }, {
      path: "location",
      component: _35b9ec54,
      pathToRegexpOptions: {"strict":true},
      name: "edit-vehicle-location___fi-sv"
    }, {
      path: "pricing",
      component: _2f1eff96,
      pathToRegexpOptions: {"strict":true},
      name: "edit-vehicle-pricing___fi-sv"
    }, {
      path: "rules",
      component: _2f7372a6,
      pathToRegexpOptions: {"strict":true},
      name: "edit-vehicle-rules___fi-sv"
    }]
  }, {
    path: "/fi-sv/fordon/:id",
    component: _7c9fe4c0,
    pathToRegexpOptions: {"strict":true},
    name: "vehicle___fi-sv"
  }, {
    path: "/fi-sv/login-as-user-from-admin/:id",
    component: _4e4c09e2,
    pathToRegexpOptions: {"strict":true},
    name: "login-as-user-from-admin-id___fi-sv"
  }, {
    path: "/fi-sv/magasin/:slug?",
    component: _52fa2039,
    meta: {"hideGettingStartedProgressBar":true},
    pathToRegexpOptions: {"strict":true},
    name: "magazine-slug___fi-sv"
  }, {
    path: "/fi-sv/messages/:conversationId?",
    component: _202b5344,
    meta: {"hideGettingStartedProgressBar":true},
    pathToRegexpOptions: {"strict":true},
    name: "messages___fi-sv"
  }, {
    path: "/fi-sv/reset-password/:resetCode?",
    component: _56eec2d9,
    pathToRegexpOptions: {"strict":true},
    name: "reset-password-resetCode___fi-sv"
  }, {
    path: "/fi-sv/verify-email/:inputCode?",
    component: _bb188bf6,
    pathToRegexpOptions: {"strict":true},
    name: "verify-email-inputCode___fi-sv"
  }, {
    path: "/fi/ajoneuvo/:id",
    component: _7c9fe4c0,
    pathToRegexpOptions: {"strict":true},
    name: "vehicle___fi"
  }, {
    path: "/fi/bookings/:id",
    component: _0d543112,
    pathToRegexpOptions: {"strict":true},
    name: "booking___fi"
  }, {
    path: "/fi/edit-vehicle/:id?",
    component: _6b619cfe,
    pathToRegexpOptions: {"strict":true},
    name: "edit-vehicle-id___fi",
    children: [{
      path: "activation",
      component: _237df477,
      pathToRegexpOptions: {"strict":true},
      name: "edit-vehicle-activation___fi"
    }, {
      path: "availability",
      component: _3e33e008,
      pathToRegexpOptions: {"strict":true},
      name: "edit-vehicle-availability___fi"
    }, {
      path: "basic",
      component: _45d28a5d,
      pathToRegexpOptions: {"strict":true},
      name: "edit-vehicle-basic___fi"
    }, {
      path: "cancellation",
      component: _74d46fa4,
      pathToRegexpOptions: {"strict":true},
      name: "edit-vehicle-cancellation___fi"
    }, {
      path: "checkin-checkout",
      component: _bd5d98f2,
      pathToRegexpOptions: {"strict":true},
      name: "edit-vehicle-checkin-checkout___fi"
    }, {
      path: "description",
      component: _3cea15eb,
      pathToRegexpOptions: {"strict":true},
      name: "edit-vehicle-description___fi"
    }, {
      path: "equipment",
      component: _b6ca6f86,
      pathToRegexpOptions: {"strict":true},
      name: "edit-vehicle-equipment___fi"
    }, {
      path: "images",
      component: _6529c159,
      pathToRegexpOptions: {"strict":true},
      name: "edit-vehicle-images___fi"
    }, {
      path: "insurance",
      component: _b897f8ee,
      pathToRegexpOptions: {"strict":true},
      name: "edit-vehicle-insurance___fi"
    }, {
      path: "location",
      component: _35b9ec54,
      pathToRegexpOptions: {"strict":true},
      name: "edit-vehicle-location___fi"
    }, {
      path: "pricing",
      component: _2f1eff96,
      pathToRegexpOptions: {"strict":true},
      name: "edit-vehicle-pricing___fi"
    }, {
      path: "rules",
      component: _2f7372a6,
      pathToRegexpOptions: {"strict":true},
      name: "edit-vehicle-rules___fi"
    }]
  }, {
    path: "/fi/lehti/:slug?",
    component: _52fa2039,
    meta: {"hideGettingStartedProgressBar":true},
    pathToRegexpOptions: {"strict":true},
    name: "magazine-slug___fi"
  }, {
    path: "/fi/login-as-user-from-admin/:id",
    component: _4e4c09e2,
    pathToRegexpOptions: {"strict":true},
    name: "login-as-user-from-admin-id___fi"
  }, {
    path: "/fi/messages/:conversationId?",
    component: _202b5344,
    meta: {"hideGettingStartedProgressBar":true},
    pathToRegexpOptions: {"strict":true},
    name: "messages___fi"
  }, {
    path: "/fi/omistajalle/:id",
    component: _8c845e38,
    pathToRegexpOptions: {"strict":true},
    name: "owner-id___fi"
  }, {
    path: "/fi/reset-password/:resetCode?",
    component: _56eec2d9,
    pathToRegexpOptions: {"strict":true},
    name: "reset-password-resetCode___fi"
  }, {
    path: "/fi/verify-email/:inputCode?",
    component: _bb188bf6,
    pathToRegexpOptions: {"strict":true},
    name: "verify-email-inputCode___fi"
  }, {
    path: "/magazine/category/:slug?",
    component: _4ec86fcc,
    meta: {"hideGettingStartedProgressBar":true},
    pathToRegexpOptions: {"strict":true},
    name: "magazine-category-slug___en"
  }, {
    path: "/magazine/tag/:slug?",
    component: _aaf5eef8,
    meta: {"hideGettingStartedProgressBar":true},
    pathToRegexpOptions: {"strict":true},
    name: "magazine-tag-slug___en"
  }, {
    path: "/no/bookings/:id",
    component: _0d543112,
    pathToRegexpOptions: {"strict":true},
    name: "booking___no"
  }, {
    path: "/no/edit-vehicle/:id?",
    component: _6b619cfe,
    pathToRegexpOptions: {"strict":true},
    name: "edit-vehicle-id___no",
    children: [{
      path: "activation",
      component: _237df477,
      pathToRegexpOptions: {"strict":true},
      name: "edit-vehicle-activation___no"
    }, {
      path: "availability",
      component: _3e33e008,
      pathToRegexpOptions: {"strict":true},
      name: "edit-vehicle-availability___no"
    }, {
      path: "basic",
      component: _45d28a5d,
      pathToRegexpOptions: {"strict":true},
      name: "edit-vehicle-basic___no"
    }, {
      path: "cancellation",
      component: _74d46fa4,
      pathToRegexpOptions: {"strict":true},
      name: "edit-vehicle-cancellation___no"
    }, {
      path: "checkin-checkout",
      component: _bd5d98f2,
      pathToRegexpOptions: {"strict":true},
      name: "edit-vehicle-checkin-checkout___no"
    }, {
      path: "description",
      component: _3cea15eb,
      pathToRegexpOptions: {"strict":true},
      name: "edit-vehicle-description___no"
    }, {
      path: "equipment",
      component: _b6ca6f86,
      pathToRegexpOptions: {"strict":true},
      name: "edit-vehicle-equipment___no"
    }, {
      path: "images",
      component: _6529c159,
      pathToRegexpOptions: {"strict":true},
      name: "edit-vehicle-images___no"
    }, {
      path: "insurance",
      component: _b897f8ee,
      pathToRegexpOptions: {"strict":true},
      name: "edit-vehicle-insurance___no"
    }, {
      path: "location",
      component: _35b9ec54,
      pathToRegexpOptions: {"strict":true},
      name: "edit-vehicle-location___no"
    }, {
      path: "pricing",
      component: _2f1eff96,
      pathToRegexpOptions: {"strict":true},
      name: "edit-vehicle-pricing___no"
    }, {
      path: "rules",
      component: _2f7372a6,
      pathToRegexpOptions: {"strict":true},
      name: "edit-vehicle-rules___no"
    }]
  }, {
    path: "/no/eier/:id",
    component: _8c845e38,
    pathToRegexpOptions: {"strict":true},
    name: "owner-id___no"
  }, {
    path: "/no/kjoretoy/:id",
    component: _7c9fe4c0,
    pathToRegexpOptions: {"strict":true},
    name: "vehicle___no"
  }, {
    path: "/no/login-as-user-from-admin/:id",
    component: _4e4c09e2,
    pathToRegexpOptions: {"strict":true},
    name: "login-as-user-from-admin-id___no"
  }, {
    path: "/no/magasin/:slug?",
    component: _52fa2039,
    meta: {"hideGettingStartedProgressBar":true},
    pathToRegexpOptions: {"strict":true},
    name: "magazine-slug___no"
  }, {
    path: "/no/messages/:conversationId?",
    component: _202b5344,
    meta: {"hideGettingStartedProgressBar":true},
    pathToRegexpOptions: {"strict":true},
    name: "messages___no"
  }, {
    path: "/no/reset-password/:resetCode?",
    component: _56eec2d9,
    pathToRegexpOptions: {"strict":true},
    name: "reset-password-resetCode___no"
  }, {
    path: "/no/verify-email/:inputCode?",
    component: _bb188bf6,
    pathToRegexpOptions: {"strict":true},
    name: "verify-email-inputCode___no"
  }, {
    path: "/se/agare/:id",
    component: _8c845e38,
    pathToRegexpOptions: {"strict":true},
    name: "owner-id___se"
  }, {
    path: "/se/bookings/:id",
    component: _0d543112,
    pathToRegexpOptions: {"strict":true},
    name: "booking___se"
  }, {
    path: "/se/edit-vehicle/:id?",
    component: _6b619cfe,
    pathToRegexpOptions: {"strict":true},
    name: "edit-vehicle-id___se",
    children: [{
      path: "activation",
      component: _237df477,
      pathToRegexpOptions: {"strict":true},
      name: "edit-vehicle-activation___se"
    }, {
      path: "availability",
      component: _3e33e008,
      pathToRegexpOptions: {"strict":true},
      name: "edit-vehicle-availability___se"
    }, {
      path: "basic",
      component: _45d28a5d,
      pathToRegexpOptions: {"strict":true},
      name: "edit-vehicle-basic___se"
    }, {
      path: "cancellation",
      component: _74d46fa4,
      pathToRegexpOptions: {"strict":true},
      name: "edit-vehicle-cancellation___se"
    }, {
      path: "checkin-checkout",
      component: _bd5d98f2,
      pathToRegexpOptions: {"strict":true},
      name: "edit-vehicle-checkin-checkout___se"
    }, {
      path: "description",
      component: _3cea15eb,
      pathToRegexpOptions: {"strict":true},
      name: "edit-vehicle-description___se"
    }, {
      path: "equipment",
      component: _b6ca6f86,
      pathToRegexpOptions: {"strict":true},
      name: "edit-vehicle-equipment___se"
    }, {
      path: "images",
      component: _6529c159,
      pathToRegexpOptions: {"strict":true},
      name: "edit-vehicle-images___se"
    }, {
      path: "insurance",
      component: _b897f8ee,
      pathToRegexpOptions: {"strict":true},
      name: "edit-vehicle-insurance___se"
    }, {
      path: "location",
      component: _35b9ec54,
      pathToRegexpOptions: {"strict":true},
      name: "edit-vehicle-location___se"
    }, {
      path: "pricing",
      component: _2f1eff96,
      pathToRegexpOptions: {"strict":true},
      name: "edit-vehicle-pricing___se"
    }, {
      path: "rules",
      component: _2f7372a6,
      pathToRegexpOptions: {"strict":true},
      name: "edit-vehicle-rules___se"
    }]
  }, {
    path: "/se/fordon/:id",
    component: _7c9fe4c0,
    pathToRegexpOptions: {"strict":true},
    name: "vehicle___se"
  }, {
    path: "/se/login-as-user-from-admin/:id",
    component: _4e4c09e2,
    pathToRegexpOptions: {"strict":true},
    name: "login-as-user-from-admin-id___se"
  }, {
    path: "/se/magasin/:slug?",
    component: _52fa2039,
    meta: {"hideGettingStartedProgressBar":true},
    pathToRegexpOptions: {"strict":true},
    name: "magazine-slug___se"
  }, {
    path: "/se/messages/:conversationId?",
    component: _202b5344,
    meta: {"hideGettingStartedProgressBar":true},
    pathToRegexpOptions: {"strict":true},
    name: "messages___se"
  }, {
    path: "/se/reset-password/:resetCode?",
    component: _56eec2d9,
    pathToRegexpOptions: {"strict":true},
    name: "reset-password-resetCode___se"
  }, {
    path: "/se/verify-email/:inputCode?",
    component: _bb188bf6,
    pathToRegexpOptions: {"strict":true},
    name: "verify-email-inputCode___se"
  }, {
    path: "/de/bookings/:id/checkin",
    component: _4ae5a8ed,
    pathToRegexpOptions: {"strict":true},
    name: "checkin___de"
  }, {
    path: "/de/bookings/:id/checkout",
    component: _5ee07d36,
    pathToRegexpOptions: {"strict":true},
    name: "checkout___de"
  }, {
    path: "/de/vehicle/:id?/book",
    component: _66faf94e,
    pathToRegexpOptions: {"strict":true},
    name: "vehicle-id-book___de"
  }, {
    path: "/dk/bookings/:id/checkin",
    component: _4ae5a8ed,
    pathToRegexpOptions: {"strict":true},
    name: "checkin___dk"
  }, {
    path: "/dk/bookings/:id/checkout",
    component: _5ee07d36,
    pathToRegexpOptions: {"strict":true},
    name: "checkout___dk"
  }, {
    path: "/dk/vehicle/:id?/book",
    component: _66faf94e,
    pathToRegexpOptions: {"strict":true},
    name: "vehicle-id-book___dk"
  }, {
    path: "/fi-sv/bookings/:id/checkin",
    component: _4ae5a8ed,
    pathToRegexpOptions: {"strict":true},
    name: "checkin___fi-sv"
  }, {
    path: "/fi-sv/bookings/:id/checkout",
    component: _5ee07d36,
    pathToRegexpOptions: {"strict":true},
    name: "checkout___fi-sv"
  }, {
    path: "/fi-sv/vehicle/:id?/book",
    component: _66faf94e,
    pathToRegexpOptions: {"strict":true},
    name: "vehicle-id-book___fi-sv"
  }, {
    path: "/fi/bookings/:id/checkin",
    component: _4ae5a8ed,
    pathToRegexpOptions: {"strict":true},
    name: "checkin___fi"
  }, {
    path: "/fi/bookings/:id/checkout",
    component: _5ee07d36,
    pathToRegexpOptions: {"strict":true},
    name: "checkout___fi"
  }, {
    path: "/fi/vehicle/:id?/book",
    component: _66faf94e,
    pathToRegexpOptions: {"strict":true},
    name: "vehicle-id-book___fi"
  }, {
    path: "/no/bookings/:id/checkin",
    component: _4ae5a8ed,
    pathToRegexpOptions: {"strict":true},
    name: "checkin___no"
  }, {
    path: "/no/bookings/:id/checkout",
    component: _5ee07d36,
    pathToRegexpOptions: {"strict":true},
    name: "checkout___no"
  }, {
    path: "/no/vehicle/:id?/book",
    component: _66faf94e,
    pathToRegexpOptions: {"strict":true},
    name: "vehicle-id-book___no"
  }, {
    path: "/se/bookings/:id/checkin",
    component: _4ae5a8ed,
    pathToRegexpOptions: {"strict":true},
    name: "checkin___se"
  }, {
    path: "/se/bookings/:id/checkout",
    component: _5ee07d36,
    pathToRegexpOptions: {"strict":true},
    name: "checkout___se"
  }, {
    path: "/se/vehicle/:id?/book",
    component: _66faf94e,
    pathToRegexpOptions: {"strict":true},
    name: "vehicle-id-book___se"
  }, {
    path: "/de/fahrzeuge/:id/buchen/bestaetigung",
    component: _bb89062e,
    pathToRegexpOptions: {"strict":true},
    name: "vehicle-id-book-confirmation___de"
  }, {
    path: "/de/fahrzeuge/:id/buchen/bezahlen",
    component: _71655db5,
    pathToRegexpOptions: {"strict":true},
    name: "vehicle-id-book-purchase___de"
  }, {
    path: "/de/fahrzeuge/:id/buchen/error",
    component: _aa7dd0f8,
    pathToRegexpOptions: {"strict":true},
    name: "vehicle-id-book-error___de"
  }, {
    path: "/de/fahrzeuge/:id/buchen/login",
    component: _30a29c65,
    pathToRegexpOptions: {"strict":true},
    name: "vehicle-id-book-login___de"
  }, {
    path: "/de/fahrzeuge/:id/buchen/personalisieren",
    component: _6e8f2a3f,
    pathToRegexpOptions: {"strict":true},
    name: "vehicle-id-book-customize___de"
  }, {
    path: "/de/fahrzeuge/:id/buchen/ueber-dich",
    component: _6cc9644a,
    pathToRegexpOptions: {"strict":true},
    name: "vehicle-id-book-about-you___de"
  }, {
    path: "/de/fahrzeuge/:id/buchen/ueberpruefen",
    component: _451178e8,
    pathToRegexpOptions: {"strict":true},
    name: "vehicle-id-book-review___de"
  }, {
    path: "/de/fahrzeuge/:id/buchen/versicherung",
    component: _f8e3bd54,
    pathToRegexpOptions: {"strict":true},
    name: "vehicle-id-book-insurance___de"
  }, {
    path: "/dk/koeretoejer/:id/book/anmeldelse",
    component: _451178e8,
    pathToRegexpOptions: {"strict":true},
    name: "vehicle-id-book-review___dk"
  }, {
    path: "/dk/koeretoejer/:id/book/bekreftelse",
    component: _bb89062e,
    pathToRegexpOptions: {"strict":true},
    name: "vehicle-id-book-confirmation___dk"
  }, {
    path: "/dk/koeretoejer/:id/book/error",
    component: _aa7dd0f8,
    pathToRegexpOptions: {"strict":true},
    name: "vehicle-id-book-error___dk"
  }, {
    path: "/dk/koeretoejer/:id/book/forsikring",
    component: _f8e3bd54,
    pathToRegexpOptions: {"strict":true},
    name: "vehicle-id-book-insurance___dk"
  }, {
    path: "/dk/koeretoejer/:id/book/koeb",
    component: _71655db5,
    pathToRegexpOptions: {"strict":true},
    name: "vehicle-id-book-purchase___dk"
  }, {
    path: "/dk/koeretoejer/:id/book/log-ind",
    component: _30a29c65,
    pathToRegexpOptions: {"strict":true},
    name: "vehicle-id-book-login___dk"
  }, {
    path: "/dk/koeretoejer/:id/book/om-dig",
    component: _6cc9644a,
    pathToRegexpOptions: {"strict":true},
    name: "vehicle-id-book-about-you___dk"
  }, {
    path: "/dk/koeretoejer/:id/book/tilpas",
    component: _6e8f2a3f,
    pathToRegexpOptions: {"strict":true},
    name: "vehicle-id-book-customize___dk"
  }, {
    path: "/fi-sv/fordon/:id/boka/anpassa",
    component: _6e8f2a3f,
    pathToRegexpOptions: {"strict":true},
    name: "vehicle-id-book-customize___fi-sv"
  }, {
    path: "/fi-sv/fordon/:id/boka/bekraftelse",
    component: _bb89062e,
    pathToRegexpOptions: {"strict":true},
    name: "vehicle-id-book-confirmation___fi-sv"
  }, {
    path: "/fi-sv/fordon/:id/boka/betala",
    component: _71655db5,
    pathToRegexpOptions: {"strict":true},
    name: "vehicle-id-book-purchase___fi-sv"
  }, {
    path: "/fi-sv/fordon/:id/boka/error",
    component: _aa7dd0f8,
    pathToRegexpOptions: {"strict":true},
    name: "vehicle-id-book-error___fi-sv"
  }, {
    path: "/fi-sv/fordon/:id/boka/forsakring",
    component: _f8e3bd54,
    pathToRegexpOptions: {"strict":true},
    name: "vehicle-id-book-insurance___fi-sv"
  }, {
    path: "/fi-sv/fordon/:id/boka/granska",
    component: _451178e8,
    pathToRegexpOptions: {"strict":true},
    name: "vehicle-id-book-review___fi-sv"
  }, {
    path: "/fi-sv/fordon/:id/boka/logga-in",
    component: _30a29c65,
    pathToRegexpOptions: {"strict":true},
    name: "vehicle-id-book-login___fi-sv"
  }, {
    path: "/fi-sv/fordon/:id/boka/om-dig",
    component: _6cc9644a,
    pathToRegexpOptions: {"strict":true},
    name: "vehicle-id-book-about-you___fi-sv"
  }, {
    path: "/fi/ajoneuvo/:id/varaa/error",
    component: _aa7dd0f8,
    pathToRegexpOptions: {"strict":true},
    name: "vehicle-id-book-error___fi"
  }, {
    path: "/fi/ajoneuvo/:id/varaa/kirjaudu-sisaan",
    component: _30a29c65,
    pathToRegexpOptions: {"strict":true},
    name: "vehicle-id-book-login___fi"
  }, {
    path: "/fi/ajoneuvo/:id/varaa/maksa",
    component: _71655db5,
    pathToRegexpOptions: {"strict":true},
    name: "vehicle-id-book-purchase___fi"
  }, {
    path: "/fi/ajoneuvo/:id/varaa/mukauta",
    component: _6e8f2a3f,
    pathToRegexpOptions: {"strict":true},
    name: "vehicle-id-book-customize___fi"
  }, {
    path: "/fi/ajoneuvo/:id/varaa/sinusta",
    component: _6cc9644a,
    pathToRegexpOptions: {"strict":true},
    name: "vehicle-id-book-about-you___fi"
  }, {
    path: "/fi/ajoneuvo/:id/varaa/tarkista",
    component: _451178e8,
    pathToRegexpOptions: {"strict":true},
    name: "vehicle-id-book-review___fi"
  }, {
    path: "/fi/ajoneuvo/:id/varaa/vahvistus",
    component: _bb89062e,
    pathToRegexpOptions: {"strict":true},
    name: "vehicle-id-book-confirmation___fi"
  }, {
    path: "/fi/ajoneuvo/:id/varaa/vakuutus",
    component: _f8e3bd54,
    pathToRegexpOptions: {"strict":true},
    name: "vehicle-id-book-insurance___fi"
  }, {
    path: "/no/kjoretoy/:id/bestill/bekreftelse",
    component: _bb89062e,
    pathToRegexpOptions: {"strict":true},
    name: "vehicle-id-book-confirmation___no"
  }, {
    path: "/no/kjoretoy/:id/bestill/error",
    component: _aa7dd0f8,
    pathToRegexpOptions: {"strict":true},
    name: "vehicle-id-book-error___no"
  }, {
    path: "/no/kjoretoy/:id/bestill/forsikring",
    component: _f8e3bd54,
    pathToRegexpOptions: {"strict":true},
    name: "vehicle-id-book-insurance___no"
  }, {
    path: "/no/kjoretoy/:id/bestill/gjennomgang",
    component: _451178e8,
    pathToRegexpOptions: {"strict":true},
    name: "vehicle-id-book-review___no"
  }, {
    path: "/no/kjoretoy/:id/bestill/kjop",
    component: _71655db5,
    pathToRegexpOptions: {"strict":true},
    name: "vehicle-id-book-purchase___no"
  }, {
    path: "/no/kjoretoy/:id/bestill/logg-inn",
    component: _30a29c65,
    pathToRegexpOptions: {"strict":true},
    name: "vehicle-id-book-login___no"
  }, {
    path: "/no/kjoretoy/:id/bestill/om-deg",
    component: _6cc9644a,
    pathToRegexpOptions: {"strict":true},
    name: "vehicle-id-book-about-you___no"
  }, {
    path: "/no/kjoretoy/:id/bestill/tilpass",
    component: _6e8f2a3f,
    pathToRegexpOptions: {"strict":true},
    name: "vehicle-id-book-customize___no"
  }, {
    path: "/se/fordon/:id/boka/anpassa",
    component: _6e8f2a3f,
    pathToRegexpOptions: {"strict":true},
    name: "vehicle-id-book-customize___se"
  }, {
    path: "/se/fordon/:id/boka/bekraftelse",
    component: _bb89062e,
    pathToRegexpOptions: {"strict":true},
    name: "vehicle-id-book-confirmation___se"
  }, {
    path: "/se/fordon/:id/boka/betala",
    component: _71655db5,
    pathToRegexpOptions: {"strict":true},
    name: "vehicle-id-book-purchase___se"
  }, {
    path: "/se/fordon/:id/boka/error",
    component: _aa7dd0f8,
    pathToRegexpOptions: {"strict":true},
    name: "vehicle-id-book-error___se"
  }, {
    path: "/se/fordon/:id/boka/forsakring",
    component: _f8e3bd54,
    pathToRegexpOptions: {"strict":true},
    name: "vehicle-id-book-insurance___se"
  }, {
    path: "/se/fordon/:id/boka/granska",
    component: _451178e8,
    pathToRegexpOptions: {"strict":true},
    name: "vehicle-id-book-review___se"
  }, {
    path: "/se/fordon/:id/boka/logga-in",
    component: _30a29c65,
    pathToRegexpOptions: {"strict":true},
    name: "vehicle-id-book-login___se"
  }, {
    path: "/se/fordon/:id/boka/om-dig",
    component: _6cc9644a,
    pathToRegexpOptions: {"strict":true},
    name: "vehicle-id-book-about-you___se"
  }, {
    path: "/bookings/:id",
    component: _0d543112,
    pathToRegexpOptions: {"strict":true},
    name: "booking___en"
  }, {
    path: "/edit-vehicle/:id?",
    component: _6b619cfe,
    pathToRegexpOptions: {"strict":true},
    name: "edit-vehicle-id___en",
    children: [{
      path: "activation",
      component: _237df477,
      pathToRegexpOptions: {"strict":true},
      name: "edit-vehicle-activation___en"
    }, {
      path: "availability",
      component: _3e33e008,
      pathToRegexpOptions: {"strict":true},
      name: "edit-vehicle-availability___en"
    }, {
      path: "basic",
      component: _45d28a5d,
      pathToRegexpOptions: {"strict":true},
      name: "edit-vehicle-basic___en"
    }, {
      path: "cancellation",
      component: _74d46fa4,
      pathToRegexpOptions: {"strict":true},
      name: "edit-vehicle-cancellation___en"
    }, {
      path: "checkin-checkout",
      component: _bd5d98f2,
      pathToRegexpOptions: {"strict":true},
      name: "edit-vehicle-checkin-checkout___en"
    }, {
      path: "description",
      component: _3cea15eb,
      pathToRegexpOptions: {"strict":true},
      name: "edit-vehicle-description___en"
    }, {
      path: "equipment",
      component: _b6ca6f86,
      pathToRegexpOptions: {"strict":true},
      name: "edit-vehicle-equipment___en"
    }, {
      path: "images",
      component: _6529c159,
      pathToRegexpOptions: {"strict":true},
      name: "edit-vehicle-images___en"
    }, {
      path: "insurance",
      component: _b897f8ee,
      pathToRegexpOptions: {"strict":true},
      name: "edit-vehicle-insurance___en"
    }, {
      path: "location",
      component: _35b9ec54,
      pathToRegexpOptions: {"strict":true},
      name: "edit-vehicle-location___en"
    }, {
      path: "pricing",
      component: _2f1eff96,
      pathToRegexpOptions: {"strict":true},
      name: "edit-vehicle-pricing___en"
    }, {
      path: "rules",
      component: _2f7372a6,
      pathToRegexpOptions: {"strict":true},
      name: "edit-vehicle-rules___en"
    }]
  }, {
    path: "/login-as-user-from-admin/:id",
    component: _4e4c09e2,
    pathToRegexpOptions: {"strict":true},
    name: "login-as-user-from-admin-id___en"
  }, {
    path: "/magazine/:slug?",
    component: _52fa2039,
    meta: {"hideGettingStartedProgressBar":true},
    pathToRegexpOptions: {"strict":true},
    name: "magazine-slug___en"
  }, {
    path: "/messages/:conversationId?",
    component: _202b5344,
    meta: {"hideGettingStartedProgressBar":true},
    pathToRegexpOptions: {"strict":true},
    name: "messages___en"
  }, {
    path: "/owner/:id",
    component: _8c845e38,
    pathToRegexpOptions: {"strict":true},
    name: "owner-id___en"
  }, {
    path: "/reset-password/:resetCode?",
    component: _56eec2d9,
    pathToRegexpOptions: {"strict":true},
    name: "reset-password-resetCode___en"
  }, {
    path: "/vehicles/:id",
    component: _7c9fe4c0,
    pathToRegexpOptions: {"strict":true},
    name: "vehicle___en"
  }, {
    path: "/verify-email/:inputCode?",
    component: _bb188bf6,
    pathToRegexpOptions: {"strict":true},
    name: "verify-email-inputCode___en"
  }, {
    path: "/bookings/:id/checkin",
    component: _4ae5a8ed,
    pathToRegexpOptions: {"strict":true},
    name: "checkin___en"
  }, {
    path: "/bookings/:id/checkout",
    component: _5ee07d36,
    pathToRegexpOptions: {"strict":true},
    name: "checkout___en"
  }, {
    path: "/vehicle/:id?/book",
    component: _66faf94e,
    pathToRegexpOptions: {"strict":true},
    name: "vehicle-id-book___en"
  }, {
    path: "/vehicles/:id/book/about-you",
    component: _6cc9644a,
    pathToRegexpOptions: {"strict":true},
    name: "vehicle-id-book-about-you___en"
  }, {
    path: "/vehicles/:id/book/confirmation",
    component: _bb89062e,
    pathToRegexpOptions: {"strict":true},
    name: "vehicle-id-book-confirmation___en"
  }, {
    path: "/vehicles/:id/book/customize",
    component: _6e8f2a3f,
    pathToRegexpOptions: {"strict":true},
    name: "vehicle-id-book-customize___en"
  }, {
    path: "/vehicles/:id/book/error",
    component: _aa7dd0f8,
    pathToRegexpOptions: {"strict":true},
    name: "vehicle-id-book-error___en"
  }, {
    path: "/vehicles/:id/book/insurance",
    component: _f8e3bd54,
    pathToRegexpOptions: {"strict":true},
    name: "vehicle-id-book-insurance___en"
  }, {
    path: "/vehicles/:id/book/login",
    component: _30a29c65,
    pathToRegexpOptions: {"strict":true},
    name: "vehicle-id-book-login___en"
  }, {
    path: "/vehicles/:id/book/purchase",
    component: _71655db5,
    pathToRegexpOptions: {"strict":true},
    name: "vehicle-id-book-purchase___en"
  }, {
    path: "/vehicles/:id/book/review",
    component: _451178e8,
    pathToRegexpOptions: {"strict":true},
    name: "vehicle-id-book-review___en"
  }, {
    path: "/dk/*",
    component: _2efaf336,
    meta: {"hideGettingStartedProgressBar":true},
    pathToRegexpOptions: {"strict":true},
    name: "all___dk"
  }, {
    path: "/fi-sv/*",
    component: _2efaf336,
    meta: {"hideGettingStartedProgressBar":true},
    pathToRegexpOptions: {"strict":true},
    name: "all___fi-sv"
  }, {
    path: "/fi/*",
    component: _2efaf336,
    meta: {"hideGettingStartedProgressBar":true},
    pathToRegexpOptions: {"strict":true},
    name: "all___fi"
  }, {
    path: "/no/*",
    component: _2efaf336,
    meta: {"hideGettingStartedProgressBar":true},
    pathToRegexpOptions: {"strict":true},
    name: "all___no"
  }, {
    path: "/se/*",
    component: _2efaf336,
    meta: {"hideGettingStartedProgressBar":true},
    pathToRegexpOptions: {"strict":true},
    name: "all___se"
  }, {
    path: "/de/*",
    component: _2efaf336,
    meta: {"hideGettingStartedProgressBar":true},
    pathToRegexpOptions: {"strict":true},
    name: "all___de"
  }, {
    path: "/",
    component: _2dfb1658,
    meta: {"hideGettingStartedProgressBar":true},
    pathToRegexpOptions: {"strict":true},
    name: "index___en"
  }, {
    path: "/*",
    component: _2efaf336,
    meta: {"hideGettingStartedProgressBar":true},
    pathToRegexpOptions: {"strict":true},
    name: "all___en"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
