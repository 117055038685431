/* eslint-disable no-param-reassign, no-shadow */

export const mutationTypes = {
    SET_FLAGS: 'FEATURE_FLAGS/SET_FLAGS',
};

export const actionTypes = {
    SET_FLAGS: 'FEATURE_FLAGS/SET_FLAGS',
};

const initialState = () => ({
    flags: [],
    isLoaded: false,
});

export const state = initialState;

export const getters = {
    featureFlags: state => state.flags,
    featureFlagsLoaded: state => state.isLoaded,
    getFeatureFlag: state => flag => state.flags ? state.flags[flag] : undefined,
    isFeatureEnabled:
        state =>
        (flag, enabledValue = true) =>
            state.flags && state.flags[flag] === enabledValue,
    isNewBookingProcessOptimisationEnabled: state => {
        const featureFlag = state.flags['experiment-booking-process-2025-02'] || '';

        return featureFlag === 'new';
    },
};

export const mutations = {
    [mutationTypes.SET_FLAGS](state, flags) {
        state.flags = flags;
        state.isLoaded = true;
    },
};

export const actions = {
    [actionTypes.SET_FLAGS]({ commit }, flags) {
        commit(mutationTypes.SET_FLAGS, flags);
    },
};

export default {
    namespaced: false,
    state,
    getters,
    mutations,
    actions,
};
