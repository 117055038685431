<template>
    <div class="bare-layout-wrapper" :class="`lang-${$i18n.locale}`">
        <Nuxt />
        <Modal v-if="modalConfig !== null" :config="modalConfig" />
        <SlideUpModal v-else-if="slideUpModalConfig !== null && !isLargeScreen" :config="slideUpModalConfig" />
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { actionTypes as configActions } from '~/store/config';
import userModalsHandlerMixin from '~/mixins/userModalsHandlerMixin';
import logoutRedirectMixin from '~/mixins/logoutRedirectMixin';
import posthogMixin from '~/mixins/posthogMixin';
import intercomMixin from '~/mixins/intercomMixin';
import Modal from '~/components/modals/Modal';
import SlideUpModal from '~/components/modals/SlideUpModal';

export default {
    components: {
        Modal,
        SlideUpModal,
    },
    mixins: [userModalsHandlerMixin, logoutRedirectMixin, posthogMixin, intercomMixin],
    middleware: ['isMobileOrLarge'],
    async fetch() {
        try {
            await this.$store.dispatch(configActions.FETCH_RATES);
        } catch (err) {
            this.$sentry.captureException(err);
        }
    },
    computed: {
        ...mapGetters(['isLoggedIn', 'slideUpModalConfig', 'modalConfig', 'isLargeScreen']),
    },
    mounted() {
        this.$store.dispatch(configActions.HEALTH_CHECK);
        this.checkScreenSize();
        window.addEventListener('resize', this.checkScreenSize);
    },
    destroyed() {
        window.removeEventListener('resize', this.checkScreenSize);
    },
    methods: {
        checkScreenSize() {
            const isLargeScreen = window.innerWidth > 991;
            this.$store.dispatch(configActions.SET_IS_LARGE_SCREEN, isLargeScreen);
        },
    },
};
</script>

<style lang="scss">
@import '@/sass/_variables.scss';

html {
    background: $white;
}

.bare-layout-wrapper {
    background: $white;
}
</style>
