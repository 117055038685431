// Nuxt cannot detect window size before the mounted hook
// To avoid cluttering the DOM using css queries and layout flashes, this middleware checks the userAgent and determines the size before
// It is not 100% reliable so the window.innerWidth check is still kept in default.vue

export default function ({ store, req }) {
    if (req) {
        const userAgent = req.headers['user-agent'];
        const isLarge = !/mobile/i.test(userAgent);
        store.dispatch('CONFIG/SET_IS_LARGE_SCREEN', isLarge);
    }
}
