export default class AssetBrandsService {
    constructor({ $axios }) {
        this.$axios = $axios;
    }

    async getAssetBrands() {
        const config = {
            method: 'get',
            url: `/asset-brands`,
        };

        const response = await this.$axios(config);

        return response.data.data;
    }
}
