/**
 * Installment type names
 *
 * @note Keep this in sync with the type constants in the backend (see Installment.php)
 */
export const DOWN_PAYMENT = 'down_payment';
export const RENTAL_FEE = 'rental_fee';
export const DATES_CHANGE = 'dates_change';
export const DEDUCTIBLE_BUYDOWN = 'deductible_buydown';
export const ADDITIONAL_DRIVERS = 'additional_drivers';
export const ADDITION = 'addition';
export const EXTRA_CHARGES = 'extra_charges';
export const INTERIOR_INSURANCE = 'interior_insurance';
export const ROADSIDE_ASSISTANCE = 'roadside_assistance';
export const LUGGAGE_INSURANCE = 'luggage_insurance';
export const CANCELLATION_COST_INSURANCE = 'cancellation_cost_insurance';
export const INSURANCE_COVERAGE_CHANGE = 'insurance_coverage_change';
export const YOUNG_DRIVER_INSURANCE = 'young_driver_insurance';
export const TRAVEL_ABROAD_INSURANCE = 'travel_abroad_insurance';
